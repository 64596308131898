<div class="viewbox top-index"
    *ngIf="!loading">
    <a class="back-btn back inline-block bmargin20"
        href="javascript:;"
        (click)="goPreviousUrl()"><span class="fa fa-angle-left"></span>Back to list</a>
    <!-- <h3 class="primary-font bmargin30">{{isEditMode ? 'Edit product': 'Add new product'}}</h3> -->
    <div class="form-container bg-light-gray"
        [ngClass]="{formError:errMsg}">
        <h4 class="secondary-font bmargin20">Product Detail</h4>
        <form [formGroup]="productForm"
            (ngSubmit)="saveProduct()">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.sub_category_id.errors}">
                        <label for="">Subcategory<span class="mendatory">*</span></label>
                        <ng-select formControlName="sub_category_id"
                            [items]="subCategoryList"
                            bindLabel="name"
                            bindValue="id"
                            refresh
                            [multiple]="false"
                            placeholder="Select Subcategory"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false"
                            (change)="changeSubCategory($event);">
                        </ng-select>
                        <input-validation [control]="form.sub_category_id"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.sub_category_id.errors">
                            <span *ngIf="!form.sub_category_id.valid">Select Subcategory</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.name.errors}">
                        <label for="">Product Name<span class="mendatory">*</span></label>
                        <input type="hidden"
                            formControlName="source"
                            [(ngModel)]="source" />
                        <input formControlName="id"
                            type="hidden"
                            class="form-control" />
                        <input formControlName="name"
                            type="text"
                            class="form-control"
                            placeholder="Product name" />
                        <input-validation [control]="form.name"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.name.errors">
                            <span *ngIf="!form.name.valid">Enter valid name</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-3"
                    *ngIf="showVariants">
                    <div class="form-group">
                        <label for="">Parent Product</label>
                        <input type="hidden"
                            formControlName="variant_product_id">
                        <div class="ng-autocomplete">
                            <p-autoComplete (onClear)="onClearProduct()"
                                formControlName="variant_product_name"
                                class="selectParent"
                                placeholder="Select Product"
                                [suggestions]="variants"
                                (completeMethod)="searchVariants($event.query)"
                                (onSelect)="selectVariant($event)"
                                [disabled]="enableParent">
                                <ng-template let-item
                                    let-i="index"
                                    pTemplate="item">
                                    <div class="search-product ui-helper-clearfix"
                                        style="border-bottom:1px solid #D5D5D5;width: 400px;">
                                        <p>{{item.name | titlecase}}</p>
                                        <p class="code">Impa code: {{item.impa_code || 'N/A'}} | Product Code:
                                            {{item.product_code}}</p>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>
                </div>
                <div class="col-md-12"
                    *ngIf="productVariants?.length>1">
                    <div class="form-group">
                        <label for="">Available Variant Products <small>(Select option to make parent)</small></label>
                        <div class="product-variant-box">
                            <div class="vendor-check variant-product"
                                *ngFor="let variant of productVariants">
                                <input type="radio"
                                    name="variantProductSelect"
                                    [checked]="variant.active"
                                    id="{{ variant.id }}"
                                    (change)="selectVariantProduct($event)" />
                                <label for="{{ variant.id }}"
                                    class="custom-checkbox choose-vendor">
                                    <em title="{{variant.pack_type}} {{variant.unit.name}}">{{variant.pack_type}}
                                        {{variant.unit.name}}</em>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.description.errors}">
                        <label for="">Description<span class="mendatory">*</span></label>
                        <p-editor formControlName="description"
                            id=""
                            [style]="{'height':'100px'}"
                            placeholder="Product description"></p-editor>
                        <!-- <textarea formControlName="description" id=""
              class="form-control"></textarea> -->
                        <input-validation [control]="form.description"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.description.errors">
                            <span *ngIf="!form.description.valid">Enter valid description</span>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="col-md-4">
          <div class="form-group"
            [ngClass]="{error : submitted && form.category_id.errors}">
            <label for="">Category<span class="mendatory">*</span></label>
            <ng-select formControlName="category_id"
              [items]="categoryList"
              bindLabel="name"
              bindValue="id"
              [multiple]="false"
              (change)="getSubCategory($event)"
              placeholder="Select Category"
              class="form-control"
              clearAllText="Clear"
              [clearable]="false">
            </ng-select>

            <div class="errorbox"
              *ngIf="submitted && form.category_id.errors">
              <span *ngIf="!form.category_id.valid">Select category</span>
            </div>
          </div>
        </div> -->

                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.product_code.errors}">
                        <label for="">Shipskart Code<span class="mendatory">*</span>
                            <span class="pull-right last-product-code"
                                *ngIf="lastProductCode && !codeExists">(Last product codes: {{lastProductCode}})
                                <!-- <i *ngIf="(lastProductCode && !codeExists)"
                                    placement="top"
                                    ngbPopover="{{ 'LAST PRODUCT CODES: '+(lastProductCode)}}"
                                    triggers="mouseenter:mouseleave"
                                    aria-hidden="true"
                                    class="fa fa-info-circle remark-info"
                                    style="color: #007ae0"></i> -->
                            </span>
                        </label>
                        <input formControlName="product_code"
                            type="text"
                            class="form-control"
                            placeholder="Shipskart code">
                        <input-validation [control]="form.product_code"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.product_code.errors">
                            <span *ngIf="!form.product_code.valid">Enter product code</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.hsn.errors}">
                        <label for="">HSN CODE</label>
                        <input type="text"
                            formControlName="hsn"
                            id=""
                            onkeypress="return /[0-9]/i.test(event.key)"
                            class="form-control"
                            placeholder="HSN code"
                            maxlength="6">
                        <input-validation [control]="form.hsn"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.hsn.errors">
                            <span *ngIf="!form.hsn.valid">Enter valid hsn code</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.tax.errors}">
                        <label for="">Tax</label>
                        <div class="add-price product">
                            <span class="currency-code tax-field">%</span>
                            <input type="text"
                                pKeyFilter="pnum"
                                formControlName="tax"
                                id=""
                                class="form-control tax-field"
                                placeholder="Tax"
                                maxlength="15">
                        </div>
                        <input-validation [control]="form.tax"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.tax.errors">
                            <span *ngIf="!form.tax.valid">Enter valid tax code</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.impa_code.errors}">
                        <label for="">IMPA CODE</label>
                        <input type="text"
                            formControlName="impa_code"
                            id=""
                            pKeyFilter="pnum"
                            class="form-control"
                            placeholder="IMPA code"
                            maxlength="25">
                        <input-validation [control]="form.impa_code"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.impa_code.errors">
                            <span *ngIf="!form.impa_code.valid">Enter valid impa code</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.issa_code.errors}">
                        <label for="">ISSA CODE</label>
                        <input type="text"
                            formControlName="issa_code"
                            id=""
                            class="form-control"
                            placeholder="ISSA code">
                        <input-validation [control]="form.impa_code"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.issa_code.errors">
                            <span *ngIf="!form.issa_code.valid">Enter valid issa code</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Status</label>
                        <ng-select formControlName="status"
                            [items]="status"
                            bindLabel="name"
                            bindValue="value"
                            [multiple]="false"
                            placeholder="Select status"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false">
                        </ng-select>

                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.unit_id.errors}">
                        <div class="d-flex flex-row mb-2 ">
                            <div class="d-flex flex-column mb-2">
                                <label for="">Unit<span class="mendatory">*</span></label>
                                <ng-select formControlName="unit_id"
                                    [items]="units"
                                    bindLabel="name"
                                    bindValue="id"
                                    [multiple]="false"
                                    placeholder="Select unit"
                                    class="form-control select-unit"
                                    clearAllText="Clear"
                                    [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="d-flex flex-column mb-2">
                                <label for="">Pack type </label>
                                    <input type="text"
                                        placeholder="Pack type"
                                        formControlName="pack_type"
                                        value=""
                                        class="form-control"
                                       
                                        maxlength="20">
                            </div>
                        </div> 
                        <!-- <div class="select-unit clearfix"> REMOVE FOR HERE </div> -->

                        <input-validation [control]="form.unit_id"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.unit_id.errors">
                            <span *ngIf="!form.unit_id.valid">Select valid unit</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-2"
                    *ngIf="showMakeParent">
                    <div class="form-group">
                        <label for="">Make Unit Parent</label>
                        <div class="product-variant-box">
                            <div class="vendor-check variant-product">
                                <input type="radio"
                                    name="variantProductSelect"
                                    id="-1"
                                    (change)="selectVariantProduct($event)" />
                                <label for="-1"
                                    class="custom-checkbox choose-vendor">
                                    <em title="Make Parent">Make Parent</em>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="showMakeParent ? 'col-md-4' : 'col-md-4'">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.img_name.errors}">
                        <label for="">Image</label>
                        <input type="file"
                            formControlName="img_name"
                            (change)="selectedImage($event.target.files)"
                            class="form-control"
                            accept=".png, .jpg, .jpeg">
                        <p class="file-upload-info">Max file size : 5 MB</p>
                        <div class="errorbox"
                            *ngIf="submitted && form.img_name.errors">
                            <span *ngIf="!form.img_name.valid">Please upload Image</span>
                        </div>
                    </div>
                </div>
                <div [ngClass]="showMakeParent ? 'col-md-4' : 'col-md-4'">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.file_attachment.errors}">
                        <label for="">File Attachement</label>
                        <input type="file"
                            formControlName="file_attachment"
                            (change)="selectedfileName($event.target.files)"
                            class="form-control"
                            accept=".pdf, .xlsx,.docx,.xls">
                        <p class="file-upload-info">Max file size : 5 MB</p>
                        <div class="errorbox"
                            *ngIf="submitted && form.file_attachment.errors">
                            <span *ngIf="!form.file_attachment.valid">Please upload a file</span>
                        </div>
                    </div>
                </div>
               <!--  <div class="col-md-4"
                    *ngIf="warehouses && warehouses.length>0">
                    <div class="form-group">
                        <label for="">Warehouses</label> -->
                        <ng-select formControlName="zoho_warehouses"
                            [items]="warehouses"
                            bindLabel="warehouse_name"
                            bindValue="warehouse_name"
                            [multiple]="true"
                            placeholder="Select Warehouses"
                            [closeOnSelect]="false"
                            class="hide-cross-btn form-control"
                            [clearOnBackspace]="true"
                            [clearSearchOnAdd]="true" hidden>
                        </ng-select>
                    <!-- </div>
                </div> -->
               <!--  <div class="col-md-4"
                    *ngIf="vendors && vendors.length>0">
                    <div class="form-group">
                        <label for="">Vendors</label> -->
                        <ng-select formControlName="zoho_vendors"
                            [items]="vendors"
                            bindLabel="name"
                            bindValue="name"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            placeholder="Select Vendors"
                            class="hide-cross-btn form-control"
                            [clearOnBackspace]="true"
                            [clearSearchOnAdd]="true"
                            addTagText="Create New"
                            [addTag]="true" hidden>
                        </ng-select>
                    <!-- </div>
                </div> -->
               <!--  <div class="col-md-4"
                    *ngIf="brands && brands.length>0">
                    <div class="form-group">
                        <label for="">Brand</label> -->
                        <ng-select formControlName="zoho_brand"
                            [items]="brands"
                            bindLabel="brand"
                            bindValue="brand"
                            [multiple]="false"
                            placeholder="Select Brands"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false"
                            addTagText="Create New"
                            [addTag]="true" hidden>
                        </ng-select>
                    <!-- </div>
                </div> -->
                <div class="col-md-4"
                    *ngIf="unit && unit.length>0">
                    <div class="form-group"
                        [ngClass]="{error : submitted && form.zoho_unit.errors}">
                        <div class="d-flex flex-row mb-2 ">
                            <div class="d-flex flex-column mb-2">
                                <label for="">Unit<span class="mendatory">*</span></label>
                                <ng-select formControlName="zoho_unit"
                                    [items]="unit"
                                    bindLabel="unit"
                                    bindValue="unit"
                                    [multiple]="false"
                                    placeholder="Select Unit"
                                    class="form-control select-unit"
                                    clearAllText="Clear"
                                    [clearable]="false">
                                </ng-select>
                            </div>
                            <div class="d-flex flex-column mb-2">
                                <label for="">Pack type </label>
                                <input type="text"
                                    placeholder="Pack type"
                                    formControlName="zoho_pack_type"
                                    value=""
                                    class="form-control"
                                    maxlength="20">
                            </div>
                        </div>
                        <!-- <label for="">Unit<span class="mendatory">*</span></label>
                        <div class="select-unit clearfix">
                            <input type="text"
                                placeholder="Unit"
                                formControlName="zoho_pack_type"
                                value=""
                                class="form-control"
                                maxlength="20">
                            <ng-select formControlName="zoho_unit"
                                [items]="unit"
                                bindLabel="unit"
                                bindValue="unit"
                                [multiple]="false"
                                placeholder="Select Unit"
                                class="form-control"
                                clearAllText="Clear"
                                [clearable]="false">
                            </ng-select>
                        </div> -->
                        <input-validation [control]="form.zoho_unit"
                            [submit]="submitted"></input-validation>
                    </div>
                </div>
                <!-- <ng-container formGroupArray="zoho_taxes">
                    <ng-container>
                        <div class="col-md-4"
                            *ngIf="taxes && taxes.length>0">
                            <div class="form-group">
                                <label for="">Inter Tax<span class="mendatory">*</span></label>
                                <ng-select [items]="interTax"
                                    bindLabel="tax_name"
                                    bindValue="tax_id"
                                    [multiple]="false"
                                    placeholder="Select Inter Tax Name"
                                    class="form-control"
                                    clearAllText="Clear"
                                    [clearable]="false"
                                    (change)="setInterTax($event)">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-4"
                            *ngIf="taxes && taxes.length>0">
                            <div class="form-group">
                                <label for="">Intra Tax<span class="mendatory">*</span></label>
                                <ng-select [items]="intraTax"
                                    bindLabel="tax_name"
                                    bindValue="tax_id"
                                    [multiple]="false"
                                    placeholder="Select Intra Tax Name"
                                    class="form-control"
                                    clearAllText="Clear"
                                    [clearable]="false"
                                    (change)="setIntraTax($event)">
                                </ng-select>
                            </div>
                        </div>
                    </ng-container>
                </ng-container> -->
                <div class="col-md-4" *ngIf="warehouses && warehouses.length>0" >
                    <div class="form-group">
                        <label for="">Zoho Price<span class="mendatory">*</span></label>
                        <input type="text"
                            value="1"
                            placeholder="Enter Zoho Price"
                            formControlName="zoho_price"
                            class="form-control">
                    </div>
                    <input-validation [control]="form.zoho_price"
                        [submit]="submitted"></input-validation>
                </div>
                <div class="col-md-12 tmargin10 text-right">
                    <button class="btn btn-primary"
                        type="submit"
                        value="" >
                        <ng-container *ngIf="isSubmitted">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                        </ng-container>
                        {{isEditMode ? (isRequested ? 'Add to Catalog' : 'Update') : 'Submit'}}</button>
                </div>
            </div>
        </form>
    </div>

</div>
