import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@app/authentication/services/loading.service';
import { KycDetails } from '@app/modules/kyc/models/kyc.modal';
import { KycService } from '@app/modules/kyc/services/kyc.service';
import { AppConstants } from '@app/shared/constants/app.constants';
import { SharedService } from '@app/shared/service/shared.service';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {
  fileName: string;
  agentFileName: string;
  countryList = [];
  countryId: number;
  @Input() vendorId;
  @Input() vendorEmail;
  @Input() company;
  @Input() role;
  @Output() formFilled: EventEmitter<any> = new EventEmitter();
  @Output() vendorEmails: EventEmitter<any> = new EventEmitter();
  //@Output() updatePrev: EventEmitter<any> = new EventEmitter();
  @Output() updateNext: EventEmitter<any> = new EventEmitter();
  companyDetailsForm: FormGroup;
  formData: FormData;
  countryPortForms: {
    portList: any[],
    countryPortForm: any,
    portSelected: any,
  }[] = [];
  natureOfBusinessCheck: string[] = [];
  otherNatureOfBusiness = '';
  otherTypeOfBusiness = '';
  otherTypeOfBusinessValue = '';
  otherNature = true;
  otherType = true;
  selectOtherType = false;
  selectOtherNature = false;
  categoryIds = [];
  showAgentCertificate = false;
  submitted: boolean;
  inValidGstFileSize: boolean;
  inValidAgentFileSize: boolean;
  // selectGstFile = false;
  // selectAgentFile = true;
  // years = [];
  sameCompanyAddress = false;
  companyDetails: KycDetails;
  natureOfBusiness: string[] = [];
  gstCertificate = '';
  authorizedAgentCertificate = '';
  urlRegx = AppConstants.URL_PATTERN;
  disableAddress = true;
  countryCode;
  selectPrimary = false;
  currencyDropdown = [];
  constructor(
    private kycService: KycService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private loadingService: LoadingService,
  ) {
    this.getCountry();
    this.formData = new FormData();
    this.currencyDropdown = this.sharedService.vendorCurrencyDropdown;
    // this.years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) => (i + 1950).toString());
    // this.years = this.years.map((str, index) => ({ value: str, id: index + 1 }));
  }

  ngOnInit() {
    this.generateCompanyDetailsForm();
    this.getCompanyDetails(this.vendorId, this.vendorEmail);
  }

  generateCompanyDetailsForm() {
    this.companyDetailsForm = this.formBuilder.group({
      email: [''],
      company_name: [this.role !== 1 && this.role !== 5 && this.company && this.company.name ? this.company.name : '', [Validators.required]],
      company_address_line_one: [this.role !== 1 && this.role !== 5 && this.company && this.company.address_line_one ? this.company.address_line_one : '', [Validators.required]],
      company_address_line_two: [this.role !== 1 && this.role !== 5 && this.company && this.company.address_line_two ? this.company.address_line_two : ''],
      company_address_city: [this.role !== 1 && this.role !== 5 && this.company && this.company.city ? this.company.city : '', [Validators.required]],
      company_address_email: [this.role !== 1 && this.role !== 5 && this.company && this.company.email ? this.company.email : this.vendorEmail,
      [Validators.required, CustomValidators.emailValidator]],
      company_address_pin_code: [''],
      company_address_telephone: ['', [Validators.pattern('^[0-9]{4,15}$')]],
      company_address_mobile: [this.role !== 1 && this.role !== 5 && this.company && this.company.phone ? this.company.phone : '',
      [Validators.required, Validators.pattern('^[0-9]{4,15}$')]],
      company_address_isd_code: [null, [Validators.required, Validators.minLength(2)]],
      company_address_country: [this.role !== 1 && this.role !== 5 && this.company && this.company.country_id ? this.company.country_id : null, [Validators.required]],
      mailing_address_line_one: ['', [Validators.required]],
      mailing_address_line_two: [''],
      mailing_address_city: ['', [Validators.required]],
      mailing_address_email: ['', [Validators.required, CustomValidators.emailValidator]],
      mailing_address_pin_code: [''],
      mailing_address_telephone: ['', [Validators.pattern('[0-9]{4,15}$')]],
      mailing_address_mobile: ['', [Validators.required, Validators.pattern('[0-9]{4,15}$')]],
      mailing_address_isd_code: [null, [Validators.required, Validators.minLength(2)]],
      mailing_address_country: [null, [Validators.required]],
      parent_company_name: [''],
      company_website: ['', [Validators.pattern(this.urlRegx)]],
      contact_person_details: this.formBuilder.array([]),
      type_of_business: ['', [Validators.required]],
      nature_of_business: ['', [Validators.required]],
      registration_number: [''],
      year_established: ['', [Validators.required, Validators.minLength(4)]],
      number_of_full_time_employees: ['', [Validators.required]],
      gst_number: [''],
      country_and_port_of_services: this.formBuilder.array([]),
      company_categories: ['', [Validators.required]],
      services_offered: [''],
      currencies: [null, Validators.required]
    });
  }

  get contactPersonDetails(): FormArray {
    return this.companyDetailsForm.get('contact_person_details') as FormArray;
  }

  get countryPortServices(): FormArray {
    return this.companyDetailsForm.get('country_and_port_of_services') as FormArray;
  }

  private getCountry() {
    this.sharedService.getCountry().subscribe(data => {
      this.countryList = [];
      data.forEach(item => {
        if (item.ports && item.ports.length > 0) {
          this.countryList.push(item);
        }
      });
      if (this.countryList && this.countryList.length > 0 && this.company && this.company.country_id && this.company.phone && this.role !== 1) {
        this.countryCode = this.countryList.find(x => x.id == this.company.country_id);
        this.companyDetailsForm.get('company_address_isd_code').setValue(this.countryCode.isd_code);
      }
    });
  }

  getPort(event, ind) {
    if (event && event.id && this.countryList && this.countryList.length > 0) {
      const country = this.countryList.find(x => x.id === event.id);
      if (country) {
        this.countryPortForms[ind].portList = country.ports;
      }
    }
  }

  addContactPersonDetails(name, designation, email, mobile, isPrimary): FormGroup {
    return this.formBuilder.group({
      name: [name ? name : '', [Validators.required]],
      designation: [designation ? designation : '', [Validators.required]],
      email: [email ? email : '', [Validators.required, CustomValidators.emailValidator]],
      mobile: [mobile ? mobile : '', [Validators.required, Validators.pattern('^[0-9]{4,15}$')]],
      is_primary: [isPrimary ? isPrimary : false, [Validators.required]],
    });
  }

  addContactPersonDetailsItem(name = null, designation = null, email = null, mobile = null, isPrimary = false) {
    const contactDetails = this.companyDetailsForm.get('contact_person_details') as FormArray;
    if (contactDetails && contactDetails.length > 4) {
      this.toastr.error('Maximum 5 contact persons are allowed', 'Can not add contact person');
      return false;
    } else {
      contactDetails.push(this.addContactPersonDetails(name, designation, email, mobile, isPrimary));
      if (contactDetails.controls.filter(x => x.get('is_primary').value === true).length > 0) {
        this.selectPrimary = true;
      } else {
        this.selectPrimary = false;
      }
    }
  }

  removeContactPersonDetailsItem(i) {
    (this.companyDetailsForm.get('contact_person_details') as FormArray).removeAt(i);
    const contactPerson = this.companyDetailsForm.get('contact_person_details') as FormArray;
    if (contactPerson.controls.filter(x => x.get('is_primary').value === true).length > 0) {
      this.selectPrimary = true;
    } else {
      this.selectPrimary = false;
    }
  }

  addCountryPortsItem(countryId, portId) {
    const countryPorts = this.companyDetailsForm.get('country_and_port_of_services') as FormArray;
    if (countryPorts) {
      const abstractControl = this.formBuilder.group({
        country_id: [countryId ? countryId : null, [Validators.required]],
        port_id: [portId ? portId : null, [Validators.required]],
      });
      countryPorts.push(abstractControl);
    }
  }

  addCountryPortGroup(countryId = null, portId = null) {
    if (this.countryPortForms.length < 10) {
      this.countryPortForms.push({
        portList: (countryId && portId && portId.length > 0 && this.countryList && this.countryList.length > 0 && this.countryList.find(x => x.id === countryId)) ?
          this.countryList.find(x => x.id === countryId).ports : [],
        countryPortForm: setTimeout(() => this.addCountryPortsItem(countryId, portId), 0),
        portSelected: portId && portId.length > 0 ? portId : null
      });
    } else {
      this.toastr.error('Maximum 10 countries are allowed', 'Can not add country');
    }
  }

  removeCountryPortsItem(i) {
    this.countryPortForms[i].portList = [];
    this.countryPortForms[i].portSelected = null;
    this.countryPortForms.splice(i, 1);
    (this.companyDetailsForm.get('country_and_port_of_services') as FormArray).removeAt(i);
  }

  submitCompanyDetails() {
    this.submitted = true;
    let lowerCaseCompanyEmail;
    let lowerCaseMailingEmail;
    if (this.companyDetailsForm.get('company_address_email').value) {
      lowerCaseCompanyEmail = this.companyDetailsForm.get('company_address_email').value.toLowerCase().trim();
      this.companyDetailsForm.get('company_address_email').setValue(lowerCaseCompanyEmail);
    } else if (this.companyDetailsForm.get('mailing_address_email').value) {
      lowerCaseMailingEmail = this.companyDetailsForm.get('mailing_address_email').value.toLowerCase().trim();
      this.companyDetailsForm.get('mailing_address_email').setValue(lowerCaseMailingEmail);
    }
    if (this.role !== 1 && this.vendorEmail) {
      this.companyDetailsForm.get('email').setValue(this.vendorEmail.toLowerCase());
    } else if (this.role === 1 && this.vendorEmail) {
      this.companyDetailsForm.get('email').setValue(this.vendorEmail.toLowerCase());
    } else {
      this.companyDetailsForm.get('email').setValue(lowerCaseCompanyEmail);
    }
    this.natureOfBusinessCheck.push(this.natureOfBusinessCheck.splice(this.natureOfBusinessCheck
      .findIndex(i => i.includes('Others:')), 1)[0]);
    this.natureOfBusinessCheck = this.natureOfBusinessCheck.filter(item => item);
    this.companyDetailsForm.get('nature_of_business').setValue(this.natureOfBusinessCheck);
    this.companyDetailsForm.get('type_of_business').setValue(this.otherTypeOfBusiness);
    this.companyDetailsForm.get('company_categories').setValue(this.categoryIds);
    if (this.companyDetailsForm.get('company_website').value != '' && this.companyDetailsForm.get('company_website').value != null) {
      this.companyDetailsForm.get('company_website').setValue(this.companyDetailsForm.get('company_website').value.toLowerCase().trim());
    }

    if (!this.companyDetailsForm.valid || !this.selectPrimary || !this.selectOtherType || this.otherTypeOfBusiness === '' ||
      !this.selectOtherNature || (this.selectOtherNature && !this.otherNature && this.otherNatureOfBusiness === '')) { // !this.selectGstFile || !this.selectAgentFile || 
      return false;
    } else {
      if (this.companyDetailsForm.get('contact_person_details').value.length > 0) {
        this.companyDetailsForm.get('contact_person_details').value.forEach(x => x.email = x.email.toLowerCase().trim());
      }
      Object.keys(this.companyDetailsForm.value).forEach(key => {
        this.formData.append(key, this.companyDetailsForm.value[key]);
      });
      const FormValue = this.companyDetailsForm.value;
      this.formData.append('vendor_id', this.vendorId && this.vendorId != '0' ? this.vendorId.toString() : '');
      this.formData.delete('contact_person_details');
      if (FormValue.contact_person_details != null) {
        this.formData.append('contact_person_details', JSON.stringify(this.companyDetailsForm.get('contact_person_details').value));
      }
      this.formData.delete('country_and_port_of_services');
      if (FormValue.country_and_port_of_services != null) {
        this.formData.append('country_and_port_of_services', JSON.stringify(this.companyDetailsForm.get('country_and_port_of_services').value));
      }
      this.formData.append('kyc_status', '1');
      this.loadingService.setLoading(true);
      this.kycService.companyDetails(this.formData).subscribe(res => {
        if ((this.role === 1 || this.role === 5 || this.role === 2) && !this.vendorEmail || !this.company && !this.vendorEmail) {
          this.vendorEmails.emit(lowerCaseCompanyEmail);
        }
        this.loadingService.setLoading(false);
        this.toastr.success('Successfully saved!');
        this.updateNext.emit();
        // this.getCompanyDetails(this.vendorId, this.vendorEmail, 1);
        this.submitted = false;
        this.formData = new FormData();
      }, error => {
        this.loadingService.setLoading(false);
        this.formData = new FormData();
        if (error && error.error) {
          this.toastr.error(error.error);
        } else {
          this.toastr.error('Something went wrong, Please try again!');
        }
      });
    }
  }

  getCompanyDetails(vendorId, vendorEmail, hideLoader = false) {
    if (!hideLoader) {
      this.loadingService.setLoading(true);
    }
    const params = new HttpParams()
      .set('vendor_id', vendorId && vendorId != '0' ? vendorId.toString() : '')
      .set('email', vendorEmail ? vendorEmail : '');
    this.kycService.getCompanyDetails(params).subscribe((res: KycDetails) => {
      if (res) {
        console.log(res)
        this.loadingService.setLoading(false);
        this.companyDetails = res;
        this.formFilled.emit(this.companyDetails);
        this.companyDetailsForm.patchValue(res);
        // tslint:disable-next-line:radix
        const companyCountryId = parseInt(res.company_address_country);
        // tslint:disable-next-line:radix
        const mailingCountryId = parseInt(res.mailing_address_country);
        this.companyDetailsForm.get('company_address_country').setValue(companyCountryId);
        this.companyDetailsForm.get('mailing_address_country').setValue(mailingCountryId);
        if (res.currencies) {
          const currency = res.currencies.split('');
          this.companyDetailsForm.get('currencies').setValue(res.currencies);
        }
        if (this.checkSameAddress(res)) {
          this.sameCompanyAddress = true;
          this.disableAddress = true;
        } else {
          this.disableAddress = false;
          this.sameCompanyAddress = false;
        }
        if (res.contact_person_details && res.contact_person_details.length > 0) {
          res.contact_person_details.forEach(x => this.addContactPersonDetailsItem(x.name, x.designation, x.email, x.mobile, x.is_primary));
        } else {
          this.addContactPersonDetailsItem();
        }
        if (res.country_and_port_of_services && res.country_and_port_of_services.length > 0) {
          res.country_and_port_of_services.forEach((x, i) => {
            setTimeout(() => {
              this.addCountryPortGroup(x.country_id, x.port_id);
            }, 1500);
          });
        } else {
          this.addCountryPortGroup();
        }
        this.companyDetails.company_categories = res.company_categories.split(',');
        this.categoryIds = this.companyDetails.company_categories;
        if (res.nature_of_business.includes('Others:')) {
          this.companyDetails.nature_of_business = res.nature_of_business.split('Others:');
          this.otherNature = false;
          this.selectOtherNature = true;
          if (this.companyDetails.nature_of_business && this.companyDetails.nature_of_business.length > 1) {
            this.natureOfBusiness = this.companyDetails.nature_of_business[0].split(',');
            this.otherNatureOfBusiness = this.companyDetails.nature_of_business[1];
            this.natureOfBusinessCheck = this.natureOfBusiness;
            this.natureOfBusinessCheck.push('Others:' + this.otherNatureOfBusiness);
          } else {
            this.otherNatureOfBusiness = this.companyDetails.nature_of_business[0];
            this.natureOfBusinessCheck.push('Others:' + this.otherNatureOfBusiness);
          }
        } else {
          this.natureOfBusiness = res.nature_of_business.split(',');
          this.natureOfBusinessCheck = this.natureOfBusiness;
          this.selectOtherNature = true;
        }
        if (this.natureOfBusiness.includes('Authorized Agent') || this.natureOfBusiness.includes('Trader')) {
          this.showAgentCertificate = true;
        }
        if (res.type_of_business.includes('Others:')) {
          this.otherType = false;
          this.selectOtherType = true;
          this.otherTypeOfBusinessValue = res.type_of_business.replace('Others:', '');
          this.otherTypeOfBusiness = 'Others:' + this.otherTypeOfBusinessValue;
        } else {
          this.selectOtherType = true;
          this.otherTypeOfBusiness = res.type_of_business;
        }
        if (res.gst_certificate) {
          this.gstCertificate = res.s3_url + res.gst_certificate;
          this.formData.set('gst_certificate', res.gst_certificate);
          // this.selectGstFile = true;
        }
        if (res.authorized_agent_certificate_file) {
          this.authorizedAgentCertificate = res.s3_url + res.authorized_agent_certificate_file;
          this.formData.set('authorized_agent_certificate_file', res.authorized_agent_certificate_file);
          // this.selectAgentFile = true;
        }
      }
    }, error => {
      this.loadingService.setLoading(false);
      this.disableAddress = false;
      this.addContactPersonDetailsItem();
      this.addCountryPortGroup();
    });
  }

  resetForm() {
    if (this.companyDetails) {
      this.companyDetailsForm.reset();
      let contactPerson = (this.companyDetailsForm.get('contact_person_details') as FormArray);
      contactPerson.clear();
      let countryPorts = (this.companyDetailsForm.get('country_and_port_of_services') as FormArray);
      countryPorts.clear();
      this.countryPortForms = [];
      this.fileName = '';
      this.agentFileName = '';
      this.formData = new FormData();
      this.getCompanyDetails(this.vendorId, this.vendorEmail, true);
    } else {
      this.allFieldsReset();
    }
  }

  allFieldsReset() {
    this.companyDetailsForm.reset();
    this.sameCompanyAddress = false;
    this.disableAddress = false;
    this.natureOfBusiness = [];
    this.natureOfBusinessCheck = [];
    this.otherNature = true;
    this.otherNatureOfBusiness = '';
    this.showAgentCertificate = false;
    this.otherType = true;
    this.otherTypeOfBusiness = '';
    this.otherTypeOfBusinessValue = '';
    this.selectOtherType = false;
    this.categoryIds = [];
    if (this.companyDetails) {
      this.companyDetails.type_of_business = '';
      this.companyDetails.company_categories = [];
    }
    this.fileName = '';
    this.agentFileName = '';
    this.selectPrimary = false;
    this.companyDetailsForm.patchValue({
      company_address_line_two: '',
      mailing_address_line_two: '',
      company_address_telephone: '',
      mailing_address_telephone: '',
      parent_company_name: '',
      company_website: ''
    });
    this.formData = new FormData();
    if ((this.role !== 1 && this.role !== 5) && this.company) {
      this.companyDetailsForm.get('company_name').setValue(this.company.name);
      this.companyDetailsForm.get('company_address_line_one').setValue(this.company.address_line_one);
      this.companyDetailsForm.get('company_address_line_two').setValue(this.company.address_line_two);
      this.companyDetailsForm.get('company_address_city').setValue(this.company.city);
      this.companyDetailsForm.get('company_address_email').setValue(this.company.email);
      this.companyDetailsForm.get('company_address_country').setValue(this.company.country_id);
      this.companyDetailsForm.get('company_address_isd_code').setValue(this.countryCode.isd_code);
      this.companyDetailsForm.get('company_address_mobile').setValue(this.company.phone);
    } else {
      this.companyDetailsForm.get('company_address_email').setValue(this.vendorEmail);
    }
  }

  copyCompanyAddress(event) {
    if (event.target.checked === true) {
      this.sameCompanyAddress = true;
      const street1 = this.companyDetailsForm.get('company_address_line_one').value;
      const street2 = this.companyDetailsForm.get('company_address_line_two').value;
      const city = this.companyDetailsForm.get('company_address_city').value;
      const email = this.companyDetailsForm.get('company_address_email').value;
      const pinCode = this.companyDetailsForm.get('company_address_pin_code').value;
      const country = this.companyDetailsForm.get('company_address_country').value;
      const telephone = this.companyDetailsForm.get('company_address_telephone').value;
      const isdCode = this.companyDetailsForm.get('company_address_isd_code').value;
      const mobile = this.companyDetailsForm.get('company_address_mobile').value;
      if (street1 && city && email && pinCode && country && isdCode && mobile) {
        this.companyDetailsForm.get('mailing_address_line_one').setValue(street1);
        this.companyDetailsForm.get('mailing_address_line_two').setValue(street2);
        this.companyDetailsForm.get('mailing_address_city').setValue(city);
        this.companyDetailsForm.get('mailing_address_email').setValue(email);
        this.companyDetailsForm.get('mailing_address_pin_code').setValue(pinCode);
        this.companyDetailsForm.get('mailing_address_country').setValue(country);
        this.companyDetailsForm.get('mailing_address_telephone').setValue(telephone);
        this.companyDetailsForm.get('mailing_address_isd_code').setValue(isdCode);
        this.companyDetailsForm.get('mailing_address_mobile').setValue(mobile);
        this.disableAddress = true;
      } else {
        this.toastr.error('Fill all the company address details first!');
      }
    } else {

      this.companyDetailsForm.get('mailing_address_line_one').setValue(null);
      this.companyDetailsForm.get('mailing_address_line_two').setValue(null);
      this.companyDetailsForm.get('mailing_address_city').setValue(null);
      this.companyDetailsForm.get('mailing_address_email').setValue(null);
      this.companyDetailsForm.get('mailing_address_pin_code').setValue(null);
      this.companyDetailsForm.get('mailing_address_country').setValue(null);
      this.companyDetailsForm.get('mailing_address_telephone').setValue(null);
      this.companyDetailsForm.get('mailing_address_isd_code').setValue(null);
      this.companyDetailsForm.get('mailing_address_mobile').setValue(null);
      return this.unCheckBox();
    }
  }
  changesData(event) {
    if (event) {
      return this.unCheckBox();
    }
  }
  unCheckBox() {
    this.sameCompanyAddress = false
    this.disableAddress = false;
  }
  isVisibleCheckBox() {
    if (this.companyDetailsForm.get('company_address_line_one').value && this.companyDetailsForm.get('company_address_city').value
      && this.companyDetailsForm.get('company_address_email').value && this.companyDetailsForm.get('company_address_pin_code').value
      && this.companyDetailsForm.get('company_address_country').value && this.companyDetailsForm.get('company_address_isd_code').value && this.companyDetailsForm.get('company_address_mobile').value) {
      return false;
    } else {
      return true;
    }
  }

  checkSameAddress(res) {
    if (res.company_address_line_one === res.mailing_address_line_one && res.company_address_city === res.mailing_address_city &&
      res.company_address_pin_code === res.mailing_address_pin_code && res.company_address_country === res.mailing_address_country &&
      res.company_address_email === res.mailing_address_email && res.company_address_isd_code === res.mailing_address_isd_code && res.company_address_mobile === res.mailing_address_mobile) {
      if ((res.company_address_line_two == null || res.company_address_line_two == '' || (res.company_address_line_two && res.mailing_address_line_two && res.company_address_line_two === res.mailing_address_line_two)) &&
        (res.company_address_telephone == null || res.company_address_telephone == '' || (res.company_address_telephone && res.mailing_address_telephone && res.company_address_telephone === res.mailing_address_telephone))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  selectNatureOfBusiness(event, checked, labelName) {
    if (event === 'nature-1' && checked === true) {
      this.natureOfBusinessCheck.push(labelName);
      this.selectOtherNature = true;
    } else if (event === 'nature-2' && checked === true) {
      this.natureOfBusinessCheck.push(labelName);
      this.selectOtherNature = true;
      this.showAgentCertificate = true;
      // if (!this.agentFileName) {
      //   this.selectAgentFile = false;
      // }
    } else if (event === 'nature-3' && checked === true) {
      this.natureOfBusinessCheck.push(labelName);
      this.selectOtherNature = true;
    } else if (event === 'nature-4' && checked === true) {
      this.natureOfBusinessCheck.push(labelName);
      this.selectOtherNature = true;
      this.showAgentCertificate = true;
      // if (!this.agentFileName) {
      //   this.selectAgentFile = false;
      // }
    } else if (event === 'nature-5' && checked === true) {
      this.selectOtherNature = true;
      this.otherNature = false;
    } else {
      this.spliceNatureOfBusiness(labelName);
    }
  }

  spliceNatureOfBusiness(val) {
    if (val === 'Others') {
      val = val + ':' + this.otherNatureOfBusiness;
      this.otherNature = true;
      this.otherNatureOfBusiness = '';
    } else if (val === 'Authorized Agent' || val === 'Trader') {
      const arr = ['Authorized Agent', 'Trader'];
      const multipleExist = arr.every(value => {
        return this.natureOfBusinessCheck.includes(value);
      });
      if (!multipleExist) {
        this.showAgentCertificate = false;
        this.agentFileName = '';
        this.authorizedAgentCertificate = '';
        this.formData.delete('authorized_agent_certificate_file');
        this.formData.append('authorized_agent_certificate_file', 'remove');
        // this.selectAgentFile = true;
      }
    }
    if (val && this.natureOfBusinessCheck.includes(val)) {
      this.natureOfBusinessCheck.splice(this.natureOfBusinessCheck.findIndex(x => x === val), 1);
      if (this.natureOfBusinessCheck && this.natureOfBusinessCheck.length === 0) {
        this.selectOtherNature = false;
      }
    }
  }

  otherNatureOfBusinessField(event, labelId) {
    if (this.natureOfBusinessCheck && this.natureOfBusinessCheck.length > 0 &&
      this.natureOfBusinessCheck.find(x => x.includes('Others:'))) {
      this.natureOfBusinessCheck.splice(this.natureOfBusinessCheck.findIndex(x => x.includes('Others:')), 1);
    }
    if (event !== '' && labelId === 'nature-5') {
      this.natureOfBusinessCheck.push('Others:' + this.otherNatureOfBusiness);
    }
  }

  selectTypeOfBusiness(event) {
    if (event.target.id === 'type-1' && event.srcElement.checked === true) {
      this.otherTypeOfBusiness = 'Corporate Limited';
      this.otherType = true;
      this.selectOtherType = true;
      this.otherTypeOfBusinessValue = '';
    } else if (event.target.id === 'type-2' && event.srcElement.checked === true) {
      this.otherTypeOfBusiness = 'Partnership';
      this.otherType = true;
      this.selectOtherType = true;
      this.otherTypeOfBusinessValue = '';
    } else if (event.target.id === 'type-3' && event.srcElement.checked === true) {
      this.otherType = false;
      this.selectOtherType = true;
      this.otherTypeOfBusiness = '';
    }
  }

  otherTypeOfBusinessField(event) {
    if (event.target.value !== '') {
      this.otherTypeOfBusiness = 'Others:' + this.otherTypeOfBusinessValue;
    } else {
      this.otherTypeOfBusiness = '';
    }
  }

  selectCategory(event) {
    switch (true) {
      case event.target.id === '61' && event.srcElement.checked === true:
        this.categoryIds.push('61');
        break;
      case event.target.id === '87' && event.srcElement.checked === true:
        this.categoryIds.push('87');
        break;
      case event.target.id === '56' && event.srcElement.checked === true:
        this.categoryIds.push('56');
        break;
      case event.target.id === '51' && event.srcElement.checked === true:
        this.categoryIds.push('51');
        break;
      case event.target.id === '88' && event.srcElement.checked === true:
        this.categoryIds.push('88');
        break;
      case event.target.id === '89' && event.srcElement.checked === true:
        this.categoryIds.push('89');
        break;
      case event.target.id === '55' && event.srcElement.checked === true:
        this.categoryIds.push('55');
        break;
      case event.target.id === '95' && event.srcElement.checked === true:
        this.categoryIds.push('95');
        break;
      default:
        this.removeCategory(event.target.id);
    }

    /* if (event.target.id === '61' && event.srcElement.checked === true) {
      this.categoryIds.push('61');
    } else if (event.target.id === '87' && event.srcElement.checked === true) {
      this.categoryIds.push('87');
    } else if (event.target.id === '56' && event.srcElement.checked === true) {
      this.categoryIds.push('56');
    } else if (event.target.id === '51' && event.srcElement.checked === true) {
      this.categoryIds.push('51');
    } else if (event.target.id === '88' && event.srcElement.checked === true) {
      this.categoryIds.push('88');
    } else if (event.target.id === '89' && event.srcElement.checked === true) {
      this.categoryIds.push('89');
    } else if (event.target.id === '92' && event.srcElement.checked === true) {
      this.categoryIds.push('92');
    } else if (event.target.id === '55' && event.srcElement.checked === true) {
      this.categoryIds.push('55');
    } else {
      this.removeCategory(event.target.id);
    } */
  }

  removeCategory(val) {
    if (val) {
      const ind = this.categoryIds.findIndex(x => x === val);
      this.categoryIds.splice(ind, 1);
    }
  }

  primaryContact(event, ind) {
    if (event.target.checked === true) {
      const contactPerson = this.companyDetailsForm.get('contact_person_details') as FormArray;
      for (let i = 0; i < contactPerson.controls.length; i++) {
        contactPerson.controls[i].get('is_primary').setValue(false);
      }
      contactPerson.controls[ind].get('is_primary').setValue(event.target.checked);
      this.selectPrimary = true;
    }
  }

  selectedGstFileName(files: FileList) {
    this.inValidGstFileSize = false;
    if (files.length > 0) {
      const filesize = files[0].size / 1024 / 1024;
      if (filesize > 3) {
        this.inValidGstFileSize = true;
        this.fileName = '';
      } else {
        // const reader = new FileReader();
        // reader.readAsDataURL(files[0]);
        // reader.onload = () => {
        //   this.imageUrl = reader.result;
        // };
        // this.cd.markForCheck();
        this.fileName = files[0].name;
        this.formData.set('gst_certificate', files[0], files[0].name);
        // this.selectGstFile = true;
      }
    }
  }

  selectedAgentFileName(files: FileList) {
    this.inValidAgentFileSize = false;
    if (files.length > 0) {
      const filesize = files[0].size / 1024 / 1024;
      if (filesize > 3) {
        this.inValidAgentFileSize = true;
        this.agentFileName = '';
      } else {
        this.agentFileName = files[0].name;
        this.formData.set('authorized_agent_certificate_file', files[0], files[0].name);
        // this.selectAgentFile = true;
      }
    }
  }
}
