import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { Chart } from "chart.js";
import { PanelComponent } from "../panel/panel.component";
import { FilterComponent } from "../filter/filter.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "@app/shared/service/shared.service";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "@app/shared/service/alert.service";
import { Router } from "@angular/router";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { ChartRedirectionService } from "@app/shared/service/chart-redirection.service";
@Component({
  selector: 'app-bar-chart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css']
})

export class BarChartComponent implements OnInit {
  @Input() data: NewDashboard;
  @ViewChildren('barCharts') barChart: any;
  chart: Chart
  @ViewChild('panelData', { static: false }) panelData: PanelComponent;
  @ViewChild('filterData', { static: false }) filterData: FilterComponent;
  editPanel: boolean;
  otherAxis
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  deleteModal: BsModalRef;
  filterLength: boolean;
  copyGraphData: NewDashboard;
  isDownloading: boolean;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  loadingDeletePanel: boolean;
  constructor(private bsModalService: BsModalService,
    private router: Router,
    private sharedService: SharedService,
    private toastr: ToastrService, private altService: AlertService,
    private dashboardService: DashboardService,
    private redirectionService: ChartRedirectionService
  ) { }

  ngOnInit(): void {
    this.otherAxis = this.data;
    this.checkFilter();
  }
  reloadPage(event) {
    setTimeout(() => { this.callParentMethodEvent.emit(event) }, 200)
  }
  checkFilter() {
    this.filterLength = this.data.filters.some(response => {
      return response.filter_value ? true : false;
    })
  }
  async downloadReport() {
    this.isDownloading = true;
    try {
      const res = await this.dashboardService.getReportData(this.data.report_id, null, 1);
      this.sharedService.saveAsBlob(
        res,
        'application/vnd.ms-excel',
        `PO_${this.data.report_title}.xlsx`
      );
      this.isDownloading = false;
      this.toastr.success('Downloaded Successfully.');
    } catch (error) {
      this.toastr.error('Failed to download report.');
      this.isDownloading = false;
    }
  }





  ngAfterViewInit() {
    this.copyGraphData = JSON.parse(JSON.stringify(this.data));
    // let ctx: any = document.getElementById('barChart') as HTMLElement;
    const tempGraphData = this.data.graph_data[0];
    if (this.copyGraphData.report_type == 'bar_graph_group') {
      let count = this.copyGraphData.graph_data[0].data.length;
      if (count <= this.data.graph_data.length) {
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < this.copyGraphData.graph_data.length; j++) {
            this.copyGraphData.graph_data[i].data[j] = this.data.graph_data[j].data[i].toFixed(2);
          }
          this.copyGraphData.graph_data[i].backgroundColor = new Array(this.data.graph_data.length).fill(this.data.graph_data[i].backgroundColor[i])
          this.copyGraphData.graph_data[i].label = this.data.column_keys[i + 1];
        }
        this.copyGraphData.graph_data.splice(count,)
      }
      // console.log(this.copyGraphData)
    }
    setTimeout(() => {
      var data = {
        labels: this.data.labels,
        datasets: this.copyGraphData.graph_data,
        navigation: this.data.navigation_type,
        reportType: this.data.report_type
      };
      let route = this.router;
      let canvasCharts = [];
      canvasCharts = this.barChart._results;
      canvasCharts.map((myCanvas, i) => {
        this.chart = new Chart(myCanvas.nativeElement.getContext('2d'),
          {
            type: 'bar',
            data: data,
            options: {
              interaction: {
                mode: 'nearest',
                intersect: false
              },
              onHover: (event, chartElement) => {
                const target = event.native?.target as HTMLElement;
                if (target) {
                  const element = chartElement[0];
                  const datasetIndex = element?.datasetIndex
                  target.style.cursor = this.data.navigation_type[datasetIndex + 1] ? 'pointer' : 'default';
                }
              },
              onClick: (event: any, elements, chart) => {
                if (this.data.reportType != 'bar_graph_group') {
                  const clickedElements = chart.getElementsAtEventForMode(
                    event,
                    'nearest', // Retrieves the nearest element
                    { intersect: true }, // Ensure the click intersects the element
                    true // Use the `useFinalPosition` flag for accurate positioning
                  );

                  if (clickedElements.length > 0) {
                    const element = clickedElements[0];
                    // Get the dataset index and data index
                    const datasetIndex = element.datasetIndex;
                    const dataIndex = element.index;
                    if (this.data.navigation_type[datasetIndex + 1])
                      this.redirectionService.generateFilterBasedPayload(this.data, this.data.axes[datasetIndex + 1].axis_id, null, this.data.primary_axis_ids[dataIndex]);
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom'
                }
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: this.data.reportType != 'bar_graph_group',
                    text: `${this.data.column_keys[0]}`,
                    font: {
                      weight: 'bold',
                      size: 16
                    }
                  },
                  ticks: {
                    maxRotation: 20,
                    autoSkip: false,
                    maxTicksLimit: 10,
                    font: {
                      size: 14
                    },
                    callback: (value) => {
                      // Add ellipsis to long labels
                      if (typeof this.data.labels[value] === 'string') {
                        const maxWidth = 70; // Adjust as needed
                        let label = this.data.labels[value].trim();
                        while (myCanvas.nativeElement.getContext('2d').measureText(label + '...').width > maxWidth) {
                          label = label.slice(0, -1);
                        }
                        return label + '...';
                      } else {
                        return value;
                      }
                    }
                  }
                },
                y: {
                  title: {
                    display: this.data.reportType != 'bar_graph_group',
                    text: `${this.data.column_keys[1]}`,
                    font: {
                      weight: 'bold',
                      size: 16
                    }
                  },
                  beginAtZero: true,
                  ticks: {
                    // Uncomment and adjust if you have specific step size logic
                    // stepSize: this.computeStepSize()
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: true
            }
          });
      });
    }, 500);
  }

  onResize(event: Event): void {
    if (this.chart) {
      this.chart.resize();
    }
  }
  openFilterModal() {
    this.filterData.openModel(this.data.report_id);
  }
  openPanelModal(isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.data;
    this.panelData.openModel();
  }

  panelDeleteModal(template: TemplateRef<any>) {
    this.deleteModal = this.bsModalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteModal);
  }

  deletePanel() {
    this.loadingDeletePanel = true;
    this.sharedService.deletePanel(this.data.report_id, 1).subscribe(res => {
      if (res) {
        this.toastr.success('Report Deleted Successfully!');
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data.report_id.toString());
        this.loadingDeletePanel = false;
        this.deleteModal.hide();
      }
    }, error => {
      this.toastr.error('Something went wrong!')
      this.loadingDeletePanel = false;
      this.deleteModal.hide();
    })
  }

  filterAppied(event) {
    if (this.data.report_id) {
      this.data = event[0]
      this.chart?.destroy();
      this.ngAfterViewInit();
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data.report_id.toString());
      this.checkFilter();
    }
  }
  /* Remove Filter */
  removeFilter(event) {
    this.sharedService.removeFilter(event);
    this.callParentMethodEvent.emit(event.report_id.toString());
  }
}
