<!-- ID. {{companyId}} -->
<!-- <h3 class="breadcrum primary-font bmargin30"><a routerLink="/product"><span>Product</span></a><span><span
      class="brd-crm-arrow fa fa-angle-right"></span>Details</span>
</h3> -->
<!-- <app-loading class="loaderHide" [class.loaderShow]="loading"></app-loading> -->
<div class="top-index">
    <a class="back-btn back inline-block bmargin20"
        *ngIf="product"
        href="javascript:;"
        (click)="goPreviousUrl()"><span class="fa fa-angle-left"></span>Back to list</a>
    <!-- old design  -->
    <!-- <div class="form-container bg-light-gray bmargin30"
        *ngIf="product">
        <button *ngIf="role==userRoles.ROLE_ADMIN"
            class="btn btn-danger pull-right"
            type="button"
            (click)="openModal(confirmaDelete)">Delete Product</button>
        <div class="single-box bmargin20 arrow-space">

            <h2 class="info-font bottom-line bmargin20">Product Information</h2>
            <div class="row">
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Name</label>
                        <span class="thin-font"
                            [ngClass]="{'user-select':params['price']=='true'}">{{product?.name}}</span>
                        <input type="hidden"
                            class="editable"
                            [readonly]="true" />
                        <input type="text"
            class="editable"
            [readonly]="true"
            [value]="order.order_number" />
                    </div>
                </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Shipskart Code</label>
                        <span class="thin-font">{{product.product_code}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30"
                    *ngIf="product?.brand?.name">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Brand Name</label>
                        <span class="thin-font">{{product?.brand?.name}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">IMPA Code</label>
                        <span class="thin-font">{{product.impa_code || 'N/A'}}</span>

                    </div>
                </div>
                <div class="col-md-4 bmargin30">
        <div class="viewdetail-box">
          <label class="bold-font-size-n">Category</label>
          <span class="thin-font">{{product.category.name}}</span>
        </div>
      </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Subcategory</label>
                        <span class="thin-font">{{product.sub_category?.name}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">ISSA code</label>
                        <span class="thin-font">{{product.issa_code || 'N/A'}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">HSN Code</label>
                        <span class="thin-font">{{product.hsn || 'N/A'}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Unit</label>
                        <span class="thin-font"
                            *ngIf="product.unit">{{product.pack_type}} {{product.unit.name}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30"
                    *ngIf="product.tax">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Tax</label>
                        <span class="thin-font">{{product.tax+'%'}}</span>
                    </div>
                </div>
                <div class="col-md-3 bmargin30">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Status</label>
                        <span class="thin-font"
                            [innerHtml]="product.status | status"></span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="viewdetail-box">
                        <label class="bold-font-size-n">Description</label>
                        <span class="thin-font user-select"
                            [innerHtml]="product.description"></span>
                    </div>
                </div>

            </div>
        </div>

        <div class="single-box arrow-space">
            <h2 class="info-font bottom-line bmargin20 tmargin50">Product Images <button
                    class="btn btn-primary btn-height"
                    type="button"
                    (click)="openModal(docPopup)">Add Image</button></h2>
            <div class="row">
                <div class="col-md-2 col-sm-3 bmargin30"
                    *ngFor="let prod of product.images ">
                    <a class="btncancel">&#10006;</a>
                    <img *ngIf="product.images.length != 0 &&  product.images[0].path"
                        class="product-img prod-img"
                        [src]="prod.path"
                        (error)="onImgError($event)" />
                </div>
                <div class="col-md-2 bmargin30"
                    *ngIf="product.images.length == 0 || product.images && product.images[0].path == null">
                    <img class="product-img"
                        src="/assets/images/placeholder_image.png" />
                </div>
            </div>

            <div class="row"
                *ngIf="product?.is_requested && role==userRoles.ROLE_ADMIN">
                <div class="col-md-4 bmargin30">
                    <a class="btn btn-primary column-item"
                        routerLink="/product/edit/{{product.id}}">Add to Catalogue</a>
                </div>
            </div>
        </div>
        
        <div class="single-box arrow-space"
            *ngIf="product && product.attachment.length>0">
            <h2 class="info-font bottom-line bmargin20 tmargin50">Product Attachment(s) </h2>
            <div class="row">
                <ol *ngFor="let prod of product.attachment ">
                    <a class="btncancel">&#10006;</a>
                    <li><a href="javascript:void(0)"
                            (click)="openNewPage(prod)">{{prod.title}}</a></li>
                </ol>
            </div>
        </div>
    </div> -->
</div>
<!-- old design end  -->

<!-- NEW DESIGN  -->
<div class="product-info">

    <div class="d-flex">
        <div class="p-2 w-100">
            <h4>Product Information</h4>
        </div>
        <div class="p-2 flex-shrink-1">
            <div class="edit-box"
                style="cursor: pointer;"
                routerLink="/product/edit/{{product?.id}}">
                <i class="fa fa-pencil"
                    style="color: #0683d8;"></i>
            </div>
        </div>
    </div>

    <div class="d-flex flex-wrap mb-3 product-container">
        <div class="p-2 image-container">
            <div class="d-flex flex-column mb-3 image-contain">
                <div class="p-2" *ngIf="product?.images?.length > 0 && product?.images[0]?.path">
                    <img class="product-img prod-img" [src]="product?.images[0].path" (error)="onImgError($event)" />
                </div>
                <div class="p-2" *ngIf="product?.images?.length === 0 || !product?.images[0]?.path">
                    <img src="/assets/images/placeholder_image.png" alt="Default-product-image" />
                </div>
                <div class="p-2">
                    <button class="uploadproductimage mb-2" type="button" (click)="openModal(docPopup)"> Add Image </button>
                </div>
                <div class="p-2" *ngIf="product?.is_requested && role==userRoles.ROLE_ADMIN">
                    <button class="addcatalogue" routerLink="/product/edit/{{product?.id}}"> Add to Catalogue </button>
                </div>
            </div>
        </div>
        <div class="p-2 product-infodeatils">
            <div class="d-flex flex-column mb-3">
                <table class="table-head">
                    <tbody class="product-name">
                        <tr>
                            <th scope="col">
                                <span style="font-size: 20px;" [ngClass]="{'user-select':params['price']=='true'}">{{product?.name}}</span>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <table class="table">
                    <tbody>
                        <tr>
                            <th scope="col"><span> Shipskart Code:</span></th>
                            <td scope="col"><span>{{product?.product_code || 'N/A'}}</span></td>
                        </tr>
                        <tr *ngIf="product?.brand?.name">
                            <th scope="col"><span>Brand Name:</span></th>
                            <td scope="col"><span>{{product?.brand?.name}}</span></td>
                        </tr>
                        <tr>
                            <th scope="col"><span>Sub-category:</span></th>
                            <td scope="col"><span>{{product?.sub_category?.name}}</span></td>
                        </tr>
                        <tr>
                            <th scope="row"><span>IMPA Code:</span></th>
                            <td><span>{{product?.impa_code || 'N/A'}} </span></td>
                        </tr>
                        <tr>
                            <th scope="row"><span>ISSA Code:</span></th>
                            <td><span>{{product?.issa_code || 'N/A'}}</span></td>
                        </tr>
                        <tr>
                            <th scope="row"><span>HSN Code:</span></th>
                            <td colspan="2"><span>{{product?.hsn || 'N/A'}}</span></td>
                        </tr>
                        <tr *ngIf="product?.tax">
                            <th scope="row"><span>Tax:</span></th>
                            <td colspan="2"><span>{{product?.tax+'%'}}</span></td>
                        </tr>
                        <tr>
                            <th scope="row"><span>Unit:</span></th>
                            <td colspan="2" *ngIf="product?.unit"><span>{{product?.pack_type}} {{product?.unit.name}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="ms-auto p-2 status-container">
            <div class="d-flex" [ngClass]="{active: product?.status, inactive: !product?.status}">
                <i class="fa fa-circle" aria-hidden="true" [style.color]="product?.status?'#02864A':'#CC0505'" style="padding: 4px 6px 0px 0px;"></i>
                <p style="margin-bottom: 0rem;" [innerHtml]="product?.status | status"></p>
            </div>
        </div>
    </div>

    <div class="d-flex">
        <div class="p-2 w-100">
            <h3 class="pb-2 info-descri">Description:</h3>
            <p style="color: #717D96; font-size: 14px; width: 80%;"
                [innerHtml]="product?.description"></p>
        </div>
    </div>
    <div class="delete-product">
        <button *ngIf="role==userRoles.ROLE_ADMIN"
            type="button"
            (click)="openModal(confirmaDelete)"
            class="product-delete">
            Delete Product ?
        </button>
    </div>
</div>
<!-- NEW DESIGN END  -->
<ng-template #docPopup>
    <div class="modal-body cancelmodal-spc">
        <h4 class="sure-font">Add Image</h4>
        <div class="tmargin20">
            <form [formGroup]="imgForm"
                (ngSubmit)="addDocument()">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group"
                            [ngClass]="{error : submitted && form.img_name.errors}">
                            <label for="">Image<span class="mendatory">*</span></label>
                            <span>Max file size : 5 MB</span>
                            <input type="file"
                                formControlName="img_name"
                                class="form-control"
                                (change)="selectedfileName($event.target.files)"
                                multiple>
                            <p class="file-upload-info">Max file size : 5 MB and Maximum 5 Images can be upload.</p>
                            <!-- <div class="d-flex">
                                <div class="img-responsive" *ngFor="let img of uploadImg">
                                    <img [src] ="img" alt= "please upload image" width="100" height="100">
                                </div>
                            </div> -->
                            <div class="errorbox"
                                *ngIf="submitted && form.img_name.errors">
                                <span>Please upload Image</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 tmargin10 text-right">
                        <button class="btn btn-outline-primary"
                            (click)="modalRef.hide();submitted = false;form.img_name.setValue('');"
                            type="button">
                            Cancel
                        </button>
                        <button class="btn btn-primary"
                            type="submit"
                            [disabled]="showLoader">
                            Add Image
                            <ng-container *ngIf="showLoader">
                                <span class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #confirmaDelete>
    <div class="modal-body cancelmodal-spc text-center">
        <h4 class="sure-font">Are you sure you want to delete the product from the catalogue?</h4>
        <div class="text-center tmargin20">
            <button class="btn btn-outline-primary"
                (click)="modalRef.hide()"
                type="button">
                Cancel
            </button>
            <button class="btn btn-danger text-white"
                (click)="deleteProduct();modalRef.hide()">Delete Product</button>
        </div>
    </div>
</ng-template>