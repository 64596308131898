<ng-template #feedBackformModel
  class="row">
  <div class="modal-body text-center">
    <img src="/assets/images/white_black.svg" alt="" width="150" class="bmargin10">
    <h4 class="sure-font">Feedback Form</h4>
  </div>
  <form [formGroup]="dynamicForm"
    class="col-md-12"

    *ngIf="dynamicFormData &&dynamicFormData.length>0">
    <div class="row">
      <div class="col-md-6"
        *ngIf="orderDetails && orderDetails.order_name">
        <label for="" class="label-name">Transaction Name</label>
        <p>{{orderDetails.order_name}} </p>
    </div>
      <div class="col-md-6"
        *ngIf="orderDetails && orderDetails.order_number">
        <label for="" class="label-name">Order Number</label>
        <p>{{orderDetails.order_number}}</p>
      </div>
      <div class="col-md-6"
        *ngIf="orderDetails && orderDetails.ship_detail">
        <label for="" class="label-name">Vessel Name</label>
        <p>{{orderDetails.ship_detail | titlecase}}</p>
      </div>
    </div>
    <div *ngFor="let control of dynamicFormData;let i=index">
      <div [ngSwitch]="control.type">
        <div *ngSwitchCase="'textfield'">
          <label for="" class="tmargin10 form-label label-name">{{control.name|titlecase}} <small class="mendatory" *ngIf="control.required==true">*</small></label>
          <input [formControlName]="control.id"
            placeholder="Enter {{control.name|titlecase}}"
            class="form-control">
        </div>
        <div *ngSwitchCase="'select'">
          <label for="" class="tmargin10 form-label label-name">{{control.name|titlecase}}</label>
          <ng-select [items]="control.value"
            [formControlName]="control.id"
            class="form-control"
            placeholder="{{control.label|titlecase}}"
            bindLabel="name"
            bindValue="id"></ng-select>
        </div>
        <div *ngSwitchCase="'rating'">
          <label for=""
            class="tmargin10 form-label label-name">{{control.name|titlecase}} <small class="mendatory"
              *ngIf="control.required==true">*</small>
          </label>
          <div class="star-rating">
            <span *ngFor="let _ of [1, 2, 3, 4, 5];let j = index" [attr.id]="'star_' + (j + 1)"
            (click)="control.value ? setRating(j+1, control.id, i) : setRating(j+1, control.id, i)"
            (mouseenter)="onStarHover(j+1,control.id,i)"
            (mouseleave)="onStarLeave(j+1,control.id,i)">
            <span class="star"
              [class.full]="_ <= control.value" [class.highlighted]="j + 1 <= hoveredStar[i]">&#9733;</span>
              </span>
          </div>
        </div>
        <div *ngSwitchCase="'textarea'">
          <label for="" class="tmargin10 form-label label-name">{{control.name|titlecase}} </label>
          <textarea [formControlName]="control.id"
            placeholder="{{control.label|titlecase}}"
            class="form-control"
            cols="80"
            rows="5"
            [readonly]="isDisabled"
            ></textarea>
        </div>
      </div>
    </div>
    <div *ngIf="hideFile">
      <label for="chosenFile" class="tmargin10 form-label label-name">Choose File</label>
      <input accept=".pdf" (change)="onFileChange($event)" type="file" class="form-control-file">
      <small>Remark: Only .pdf file can be uploaded.</small>
    </div>
    <div class="tmargin5">
      <p>Notes: {{notes}}</p>
    </div>
    <div class="tmargin10">
      <button class="btn btn-primary pull-right bmargin10"
        (click)="generateFormGroup();modalRef.hide()" [disabled]="feedbackFormData && feedbackFormData.length>0 ||dynamicForm.invalid">
        Submit</button>
      <button class="btn btn-outline-primary rmargin10 pull-right"
        (click)="closeModal()">Cancel</button>
    </div>
  </form>
</ng-template>
