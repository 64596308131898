import { CountryListComponent } from '@app/modules/country/components/country-list/country-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './authentication/auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './authentication/helpers/auth.guard';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { CountryDetailComponent } from './modules/country/components/country-detail/country-detail.component';
import { UserRoles } from './core/enums/user-roles';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RegisterComponent } from './register/register.component';
import { WebsiteMaintenanceComponent } from './website-maintenance/website-maintenance.component';
import { KycFormComponent } from './kyc-form/kyc-form.component';
import { FidbackFormComponent } from './feedback/feedback.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthComponent
  },
  {
    path: 'forget',
    children: [
      { path: '', component: ForgetPasswordComponent },
      { path: ':token', component: ForgetPasswordComponent }
    ]
  },
  {
    path: 'register',
    children: [

      { path: '', component: RegisterComponent },
    ]
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'requisitions',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_USER,
        UserRoles.ROLE_CAPTAIN,
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () =>
      import('./modules/requisition/requisition.module').then(
        module => module.RequisitionModule
      )
  },
  {
    path: 'orders',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_USER,
        UserRoles.ROLE_CAPTAIN,
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () =>
      import('./modules/order/order.module').then(mod => mod.OrderModule)
  },
  {
    path: 'RFQs',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/rfq/rfq.module').then(mod => mod.RfqModule)
  },
  {
    path: 'mos',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/mos/mos.module').then(mod => mod.MosModule)
  },
  {
    path: 'user',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_USER,
        UserRoles.ROLE_CAPTAIN,
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () =>
      import('./modules/user/user.module').then(mod => mod.UserModule)
  },
  {
    path: 'company',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_USER,
        UserRoles.ROLE_CAPTAIN,
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () =>
      import('./modules/company/company.module').then(mod => mod.CompanyModule)
  },
  {
    path: 'port',
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    component: DashboardComponent,
    loadChildren: () =>
      import('./modules/port/port.module').then(mod => mod.PortModule)
  },
  {
    path: 'ship',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/ship/ship.module').then(mod => mod.ShipModule)
  },
  {
    path: 'kyc',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/kyc/kyc.module').then(mod => mod.KycModule)
  },
  {
    path: 'country',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN]
    },
    children: [
      { path: '', component: CountryListComponent },
      { path: 'detail', component: CountryDetailComponent }
    ]
  },
  { path: 'message', component: SuccessMessageComponent },
  {
    path: 'category',
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN]
    },
    component: DashboardComponent,
    loadChildren: () =>
      import('./modules/category/category.module').then(
        mod => mod.CategoryModule
      )
  },
  {
    path: 'invoice',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/invoice/invoice.module').then(mod => mod.InvoiceModule)
  },
  {
    path: 'contacts',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/contacts/contacts.module').then(mod => mod.ContactsModule)
  },
  {
    path: 'po',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/po/po.module').then(mod => mod.PurchaseOrderModule)
  },
  {
    path: 'bills',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/bill/bill.module').then(mod => mod.BillModule)
  },
  {
    path: 'purchase-receive',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/purchase-receive/purchase-receive.module').then(mod => mod.PurchaseReceiveModule)
  },
  {
    path: 'product',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUBADMIN, UserRoles.ROLE_COMPANY_ADMIN] },
    loadChildren: () =>
      import('./modules/product/product.module').then(mod => mod.ProductModule)
  },
  {
    path: 'designation',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/designation/designation.module').then(
        mod => mod.DesignationModule
      )
  },
  {
    path: 'leads',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/request/request.module').then(mod => mod.RequestModule)
  },
  {
    path: 'chat',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_USER,
        UserRoles.ROLE_CAPTAIN,
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () =>
      import('./modules/help-chat/help-chat.module').then(
        mod => mod.HelpChatModule
      )
  },
  {
    path: 'inventory',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () =>
      import('./modules/new-inventory/new-inventory.module').then(mod => mod.NewInventoryModule),
    /* loadChildren: () =>
      import('./modules/inventory/inventory.module').then(mod => mod.InventoryModule) */
  },
  {
    path: 'warehouse',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/warehouse/warehouse.module').then(mod => mod.WarehouseModule)
  },
  {
    path: 'supplier',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/supplier/supplier.module').then(mod => mod.SupplierModule)
  },
  {
    path: 'oew',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/oew/oew.module').then(mod => mod.OewModule)
  },
  {
    path: 'sub-admin',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/sub-admin/sub-admin.module').then(mod => mod.SubAdminModule)
  },
  {
    path: 'registration-request',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN,
      ]
    },
    loadChildren: () => import('./modules/registration/registration.module').then(mod => mod.RegistrationModule)
  }, {
    path: 'demo',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    loadChildren: () => import('./modules/demo/demo.module').then(mod => mod.DemoModule)
  },
  {
    path: 'vendor-analytics',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN,
        UserRoles.ROLE_COMPANY_ADMIN
      ]
    },
    loadChildren: () => import('./modules/vendor-analytics/vendor-analytics.module').then(mod => mod.VendorAnalyticsModule)
  },
  { path: 'kyc-form', component: KycFormComponent },
  { path: 'website-maintenance', component: WebsiteMaintenanceComponent },
  { path: 'feedback-form', component: FidbackFormComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
