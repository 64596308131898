import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Input, OnChanges, OnInit, SimpleChanges, } from "@angular/core";
import { Router } from "@angular/router";
import { UserRoles } from "@app/core/enums/user-roles";
import type { Order } from "@app/modules/order/model/order.model";
import { OrderService } from "@app/modules/order/service/order.service";
import { SharedService } from "@app/shared/service/shared.service";
import { environment } from "@environment/environment";
import { ToastrService } from "ngx-toastr";
import { CompanyType } from "@app/modules/company/models/company.enum";

@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.css"],
})
export class OrderHistoryComponent implements OnInit, OnChanges {
  orderData: Order[];
  @Input() vendorId;
  @Input() companyId;
  role: number;
  currentCompanyType: number;
  userRole = UserRoles;
  amount: number;
  constructor(
    private orderService: OrderService,
    private sharedService: SharedService,
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.vendorId = changes["vendorId"].currentValue.id;
    this.companyId = changes["companyId"]?.currentValue;
    this.getOrder();
  }

  ngOnInit(): void {
    this.role = this.sharedService.getUserRole();
    this.currentCompanyType = this.sharedService.getCompany().type;
    this.getOrder();
  }

  getOrder() {
    let params = new HttpParams()
      .set("page", `${1}`)
      .set("details", "0")
      .set("limit", `${10}`);
    let header = new HttpHeaders().set(
      "x-filter",
      JSON.stringify({
        order_status: 4,
        company_id: this.companyId,
        vendor_id: this.vendorId,
      })
    );
    this.orderService.getAll(params, header).subscribe((data) => {
      this.orderData = [...data.results].slice(0, 5);
      this.orderData.forEach(order => {
        order.total_amount = this.calculateTotalAmount(order)
      })
      console.log('this.orderData', this.orderData);

    });
  }

  onSelect(item) {
    let url = `/orders/detail/${item.id}`;
    window.open(url, "_blank");
  }

  calculateMargin(order_detail = []) {
    let margin = 0;
    order_detail.map((item) => {
      margin += parseInt(item.margin);
    });
    return margin;
  }

  onDeliveryNoteClick(event: Event, item: Order) {
    event.preventDefault(); // Prevent the default anchor behavior
    if (item.delivery_note && item.delivery_note.length > 0) {
      window.open(this.combineLink(item.delivery_note[0].url), "_blank");
    } else {
      this.toastr.error("Delivery note not found");
    }
  }

  onFeedbackBackClick(event: Event, item: Order) {
    event.preventDefault(); // Prevent the default anchor behavior
    if (item.feedback_name) {
      window.open(this.combineLink(item.feedback_name), "_blank");
    } else {
      this.toastr.error("Feedback Form not found");
    }
  }

  combineLink(url: string): string {
    return environment.attachementUrl + url;
  }

  get hasOrderData(): boolean {
    return this.orderData && this.orderData.length > 0;
  }

  calculateTotalAmount(item) {
    let transportFee = parseFloat(item.transport_fee || 0);
    let launchCharge = parseFloat(item.launch_charge || 0);
    let totalAmount = 0;
    if (this.role == UserRoles.ROLE_COMPANY_ADMIN && this.currentCompanyType == CompanyType.Seller) {
      item.order_detail.forEach(ele => {
        ele.price = this.sharedService.removeMarginAmt(ele);
        totalAmount += (+ele.price * ele.quantity);
      })
    } else {
      item.order_detail.forEach(ele => {
        totalAmount += (+ele.price * ele.quantity);
      })
    }
    if (item.transport_fee_tax) {
      transportFee += transportFee * (item.transport_fee_tax / 100);
    }
    if (item.launch_charge_tax) {
      launchCharge += launchCharge * (item.launch_charge_tax / 100);
    }
    let amount = totalAmount + transportFee + launchCharge;
    return amount;  // Return the calculated total without modifying the item itself
  }
}
