<div class="row"
    *ngIf="showExpiredProduct">
    <div class="col-md-12 col-sm-12">
        <div class="expire-products-alert">
            <p><b>Prices of {{goingToExpireProduct}} products</b> are expired & going to expire this week, <a
                    href="javascript:void(0)"
                    (click)="navigate()">click here</a> to update prices. <button type="button"
                    (click)="showExpiredProduct=false"
                    class="remove-alertbox">&times;</button></p>
        </div>
    </div>
</div>
<div class="button-container">
    <h3 class="rs-top-space product-title">Products</h3>
    <div style="float: right;">
        <div class="filters inline-block">
            <div class="form-group country-port"
                *ngIf="(role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN) && productPrices">
                <label for="">Vendors</label>
                <ng-select [(ngModel)]="vendorId"
                    [items]="companys"
                    bindLabel="name"
                    bindValue="id"
                    (change)="getPort();vendorEvent($event);"
                    [multiple]="false"
                    [placeholder]="companys?'Select Vendor':'Loading..'"
                    title="Select Vendor"
                    class="port-country-dropdown form-control rmargin10"
                    [clearable]="false">
                </ng-select>
            </div>
            <div class=" country-port defaultcurrency form-group"
                *ngIf="((role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN) && productPrices) || (role == userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || (role == userRoles.ROLE_SUBADMIN && productPrices)">
                <label for="currency">Currency</label>
                <ng-select [(ngModel)]="selectedCurrency"
                    [items]="currencyDropdown"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Currency"
                    title="Select Currency"
                    class="port-country-dropdown form-control rmargin10"
                    [clearable]="false">
                </ng-select>
                <span class="small lmargin10 weight600">Default Currency : <span
                        class="default-code">{{defaultCurrency}}</span></span>
            </div>
            <div class=" country-port form-group"
                *ngIf="(((role == userRoles.ROLE_ADMIN &&(vendorId==210)) || (role == userRoles.ROLE_SUBADMIN && vendorId==210)) && productPrices) || (role == userRoles.ROLE_COMPANY_ADMIN && company.type === 2 && (vendorId==210)) || (role == userRoles.ROLE_SUBADMIN && productPrices)">
                <label for="currency">Shipping Company</label>
                <ng-select [(ngModel)]="shippingCompanyId"
                    [items]="shippingCompanyList"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Shipping Company"
                    title="Select Company"
                    (change)="changeShippingCompany($event)"
                    class="port-country-dropdown form-control rmargin10"
                    [clearable]="true">
                </ng-select>
            </div>
            <div class="form-group country-port"
                *ngIf="role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2 || ((role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN) && productPrices)">
                <label for="">Ports</label>
                <ng-select [items]="portList"
                    bindLabel="port"
                    bindValue="port_id"
                    [(ngModel)]="portId"
                    (change)="setPort($event)"
                    [multiple]="false"
                    placeholder="Select Port"
                    title="Select Port"
                    class="port-country-dropdown form-control rmargin10"
                    clearAllText="Clear"
                    [clearable]="false">
                </ng-select>
            </div>
            <div class="form-group country-port"
                *ngIf="role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2 ||(role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN) && productPrices">
                <label for="">Sub-Category</label>
                <ng-select [items]="subCategoryList"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="subCategoryId"
                    [multiple]="false"
                    placeholder="Select Sub-Category"
                    title="Select Sub-Category"
                    class="port-country-dropdown form-control rmargin10"
                    clearAllText="Clear"
                    [clearable]="true"
                    (change)="filteBySubcategory($event);"
                    (remove)="onRemoveCategory($event)">
                </ng-select>
            </div>
            <div class="country-port">
                <button class="weight600 btn empty-cart filter lmargin10 country-port form-group"
                    (click)="openFilters = !openFilters;msg = false;"><span class="fa fa-filter"></span>
                    Filters</button>
                <button class="btn empty-cart filter lmargin10 weight600 font12 country-port form-group"
                    *ngIf="(role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) ||(role == userRoles.ROLE_ADMIN || (role == userRoles.ROLE_SUBADMIN && showAddEditProductPrices)) && productPrices"
                    (click)="openModel(importProductPrice)"><span class="fa fa-upload"></span> Import Product
                    Prices</button>
                <button *ngIf="role == userRoles.ROLE_ADMIN && !productPrices"
                    class="btn empty-cart filter country-port form-group"
                    (click)="resetImportForm();openModal(ImportProduct);"><span class="fa fa-upload"></span> Import
                    <span class="d-none d-sm-inline-block">Products</span></button>
                <button class="btn buttn req export lmargin10 weight600 country-port form-group"
                    *ngIf="role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2 ||(role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN) && productPrices"
                    [disabled]="(!vendorId && !portId) || loaderView"><span class="fa fa-download"></span> Export
                    <span class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="loaderView"></span>
                    <ul class="inner-list outer">
                        <li><a href="javascript:void(0)">Classic Price
                                <ul class="inner-list">
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'INR',xlsx,0)">Products in INR</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'USD',xlsx,0)">Products in USD</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'JPY',xlsx,0)">Products in JPY</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'SGD',xlsx,0)">Products in SGD</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'EUR',xlsx,0)">Products in EUR</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'AED',xlsx,0)">Products in AED</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'AUD',xlsx,0)">Products in AUD</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'QAR',xlsx,0)">Products in QAR</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'SAR',xlsx,0)">Products in SAR</a></li>
                                </ul>
                            </a></li>
                        <li *ngIf="vendorId==210"><a href="javascript:void(0)">Oew Price
                                <ul class="inner-list">
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'INR',xlsx,1)">Products in INR</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'USD',xlsx,1)">Products in USD</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'JPY',xlsx,1)">Products in JPY</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'SGD',xlsx,1)">Products in SGD</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'EUR',xlsx,1)">Products in EUR</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'AED',xlsx,1)">Products in AED</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'AUD',xlsx,1)">Products in AUD</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'QAR',xlsx,1)">Products in QAR</a></li>
                                    <li><a href="javascript:void(0)"
                                            (click)="exportProducts(0, 'SAR',xlsx,1)">Products in SAR</a></li>
                                </ul>
                            </a></li>
                    </ul>
                </button>
                <button
                    *ngIf="(role == userRoles.ROLE_ADMIN && !productPrices) || (role == userRoles.ROLE_SUBADMIN && !productPrices)"
                    class="btn empty-cart filter lmargin10 form-group"
                    (click)="openExportModal(exportPopup)"><span class="fa fa-download"></span> Export {{ filterApplied
                    ? 'List'
                    : 'Products' }}</button>
            </div>

            <a href="javascript:void(0)"
                *ngIf="(role == userRoles.ROLE_ADMIN && !productPrices) || (role == userRoles.ROLE_SUBADMIN && showAddEditToSubAdmin && !productPrices)"
                class="btn buttn req form-group lmargin10"
                routerLink="/product/add"> <span class="d-none d-sm-inline-block">Add</span><span
                    class="d-inline-block d-sm-none">+</span> Product</a>
            <!-- <a class="btn buttn req" *ngIf="role== userRoles.ROLE_ADMIN && !productPrices">Sync with Zoho</a> -->
            <span (click)="openFilters = !openFilters"
                class="click-shadow"
                [ngClass]="{'show': openFilters}"></span>
            <div class="filter-options"
                [ngClass]="{'show': openFilters}">
                <a href="javascript:void(0)"
                    (click)="openFilters = false"
                    class="close-filter-btn">&#215;</a>
                <label class="required-some-data"
                    *ngIf="msg">Please Enter Some Value to Filter Data.</label>
                <form [formGroup]="filterForm"
                    (ngSubmit)="filterData(1)">
                    <div class="row">
                        <div class="col-md-4 bmargin20">
                            <label for="">Product Name</label>
                            <input formControlName="name"
                                placeholder="Product Name"
                                type="text"
                                class="form-control" />
                        </div>
                        <div class="col-md-4 bmargin20">
                            <label for="">IMPA Code</label>
                            <input formControlName="impa_code"
                                placeholder="IMPA Code"
                                type="text"
                                class="form-control" />
                        </div>
                        <div class="col-md-4 bmargin20">
                            <label for="">Shipskart Code</label>
                            <input formControlName="product_code"
                                placeholder="Shipskart Code"
                                type="text"
                                class="form-control" />
                        </div>
                        <div class="col-md-4 bmargin20">
                            <div class="form-group">
                                <label for="">Category</label>
                                <ng-select class="hide-cross-btn form-control"
                                    [items]="categories"
                                    formControlName="category_id"
                                    #ngCategorySelect
                                    bindLabel="name"
                                    bindValue="id"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    placeholder="Select Category"
                                    [clearOnBackspace]="true"
                                    [clearSearchOnAdd]="true"
                                    (change)="categorySelected($event)">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-4"
                            *ngIf="(role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN) && !productPrices">
                            <div class="form-group">
                                <label for="">Status</label>
                                <ng-select formControlName="status"
                                    [items]="status"
                                    bindLabel="name"
                                    bindValue="value"
                                    [multiple]="false"
                                    placeholder="Select status"
                                    class="form-control"
                                    clearAllText="Clear"
                                    [clearable]="false">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-md-12">
                        <input class="btn btn-outline-primary"
                            type="button"
                            value="Clear Filter"
                            (click)="resetForm(1)" />
                        <input class="btn btn-primary"
                            type="submit"
                            value="Apply" />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="pull-right"
        (mouseenter)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave($event)"
        [ngStyle]="priceHistory?{'z-index': '1'} : {'z-index': '1'}">
        <div class="search-categories search-field">
            <form [formGroup]="filterForm">
                <div class="searchbox"
                    [class.showCross]="selectedAutocomplete">
                    <div class="ng-autocomplete">
                        <p-autoComplete formControlName="name"
                            #autocomplete
                            placeholder="Search Product by Name, Product Code or IMPA "
                            [ngModel]="selectedAutocomplete"
                            [suggestions]="suggestion"
                            (keyup)="showCrossBtn($event)"
                            (completeMethod)="search($event)"
                            (keyup.enter)="filterData(2)"
                            (onSelect)="doOnSelect($event)">
                            <ng-template let-suggestion
                                let-i="index"
                                pTemplate="item"
                                styleClass="wid100">
                                <div class="search-product ui-helper-clearfix"
                                    style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                    <p class="code">{{suggestion.name | titlecase}}</p>
                                    <p class="code impa">Product Code: {{suggestion.product_code || 'N/A'}}</p>
                                    <p class="code impa">Impa code: {{suggestion.impa_code || 'N/A'}}</p>
                                    <p class="code impa">Unit: {{suggestion.unit || 'N/A'}}</p>
                                    <p class="code impa">Pack Type: {{suggestion.pack_type || 'N/A'}}</p>
                                </div>
                                <div *ngIf="suggestion.length == i-1"><button>View All</button></div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <button class="find-btn cross-btn"
                        (click)="resetForm(2)"
                        type="button">&times;</button>
                    <button class="find-btn"
                        type="button"
                        (click)="filterData(1)"><span class="fa fa-search"></span></button>
                </div>
            </form>
        </div>
        <button
            *ngIf="(role == userRoles.ROLE_ADMIN || (role == userRoles.ROLE_SUBADMIN && showAddEditProductPrices)) && productPrices || role == userRoles.ROLE_COMPANY_ADMIN && company.type === 2"
            class="btn buttn req price-history lmargin10"
            routerLink="/product/vendor-price-comparison"
            [queryParams]="{}">Price History</button>
    </div>
</div>


    <div class="viewbox">
        <div class="data-table tmargin20"
            *ngIf="!loading">
            <ngx-datatable class="material paging-table top"
                [class.new-request]="role !== userRoles.ROLE_ADMIN && role !== userRoles.ROLE_SUBADMIN"
                [class.stop-click]="isLoading"
                #productTable
                [rows]="rows"
                [scrollbarH]="true"
                [columnMode]="'force'"
                [headerHeight]="40"
                [footerHeight]="40"
                [rowHeight]="'auto'"
                [externalPaging]="true"
                [loadingIndicator]="isLoading"
                [count]="page.count"
                [offset]="page.offset"
                (page)="setPage($event)"
                [limit]="page.limit"
                (sort)="sortCallback($event)">
                <ngx-datatable-column
                    *ngIf="(role == userRoles.ROLE_ADMIN && !productPrices) || (role == userRoles.ROLE_SUBADMIN && showAddEditToSubAdmin && !productPrices)"
                    headerClass="no-arrow"
                    name=""
                    prop=""
                    frozenLeft="True"
                    [width]="40"
                    [maxWidth]="40">
                    <!-- *ngIf="(role !== userRoles.ROLE_ADMIN && (role !==userRoles.ROLE_COMPANY_ADMIN && company.type === 2))" -->
                    <ng-template ngx-datatable-cell-template
                        let-rowIndex="rowIndex"
                        let-value="value"
                        let-row="row">
                        <a href="javascript:void(0)"
                            routerLink="/product/edit/{{ value.id }}"
                            target="_blank"><i class="fa fa-pencil"></i></a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Product Name"
                    prop=""
                    [width]="200">
                    <ng-template ngx-datatable-cell-template
                        let-rowIndex="rowIndex"
                        let-value="value"
                        let-row="row">
                        <a href="javascript:void(0)"
                            target="_blank"
                            (click)="navigateDetail(row.id)"
                            class="user-select">{{value.name |titlecase}}</a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Subcategory"
                    prop=""
                    [width]="180">
                    <ng-template ngx-datatable-cell-template
                        let-rowIndex="rowIndex"
                        let-value="value"
                        let-row="row">
                        {{value.sub_category |titlecase}}
                        <br> Brand Name: <b>{{ value.brand_name}}</b>
                    </ng-template>
                </ngx-datatable-column>

            <ngx-datatable-column name="Lead Time(Days)"
                prop=""
                *ngIf="productPrices">
                <ng-template ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row">
                    <span>{{value.deliverable_in}}</span><br>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Product Code"
                prop=""
                [width]="150">
                <ng-template ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row">
                    {{value.product_code}}<br> {{'IMPA: ' + value.impa_code}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Unit"
                prop="unit"
                [width]="90">
                <ng-template ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row">
                    {{value}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Pack Size"
                prop="pack_type"
                [width]="100">
                <ng-template ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row">
                    {{value}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Product Price"
                prop=""
                [width]="150"
                *ngIf="(role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role === userRoles.ROLE_ADMIN || role === userRoles.ROLE_SUBADMIN) && productPrices)">
                <ng-template ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row">
                    {{selectedCurrency}}: {{ getPrices(value)}}
                    <p class="font-10 bmargin0"
                        *ngIf="value.expiry_date">
                        Expiry:
                        <a title="Update Expiry Date" *ngIf="(role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role === userRoles.ROLE_ADMIN || (role === userRoles.ROLE_SUBADMIN && showAddEditProductPrices)))"
                            href="javascript:void(0)"
                            (click)="productExpiryModal(expiryDate, value.inrPrice.id)">
                            {{value.expiry_date == 'None' ? 'N/A' : getDate(value.expiry_date)}}
                        </a>
                        <span *ngIf="(role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role !== userRoles.ROLE_ADMIN || (role === userRoles.ROLE_SUBADMIN && !showAddEditProductPrices)))">
                            {{value.expiry_date == 'None' ? 'N/A' : getDate(value.expiry_date)}}
                        </span>
                    </p>                    
                    <p *ngIf="(vendorId==210)" class="bmargin0">{{selectedCurrency}}: {{ getOewPrice(value)}}
                        (OEW)</p>
                    <p class="font-10 bmargin0"
                        *ngIf="(value.oew_expiry_date && (vendorId==210) && ((role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role === userRoles.ROLE_ADMIN || role === userRoles.ROLE_SUBADMIN))))">
                        Expiry: <a title="Update Expiry Date" *ngIf="(value.oew_expiry_date && (vendorId==210) && (role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role == userRoles.ROLE_ADMIN || (role === userRoles.ROLE_SUBADMIN && showAddEditProductPrices))))"
                            href="javascript:void(0)"
                            (click)="productExpiryModal(expiryDate, value.inrOewPrice.id)">{{value.oew_expiry_date=="None"?'N/A':(getDate(value.oew_expiry_date))}}</a>
                            <span *ngIf="(value.oew_expiry_date && (vendorId==210) && (role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role !== userRoles.ROLE_ADMIN || (role === userRoles.ROLE_SUBADMIN && !showAddEditProductPrices))))">
                                {{value.oew_expiry_date=="None"?'N/A':(getDate(value.oew_expiry_date))}}
                            </span>
                        </p>
                        <!-- <br>USD: {{ value.usdPrice == null ? '–' : value.usdPrice.price | number:'1.2-2' }} -->
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Update Price"
                    prop=""
                    [width]="120"
                    *ngIf="(role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2) || ((role === userRoles.ROLE_ADMIN || showAddEditProductPrices) && productPrices)">
                    <ng-template ngx-datatable-cell-template
                        let-rowIndex="rowIndex"
                        let-value="value"
                        let-row="row">
                        <a href="javascript:void(0);"
                            class="btn btn-primary add text-white"
                            (click)="openPriceModal(editPricePopup, row, selectedCurrency,false);">{{(row.inrPrice !=
                            null
                            &&
                            row.inrPrice.price > 0 || row.usdPrice != null && row.usdPrice.price > 0 || row.jpyPrice !=
                            null
                            && row.jpyPrice.price > 0 || row.sgdPrice != null
                            && row.sgdPrice.price > 0 || row.eurPrice != null
                            && row.eurPrice.price > 0) ? 'Edit Price' : 'Add Price'}}</a>
                        <br>
                        <a *ngIf="vendorId==210"
                            href="javascript:void(0);"
                            class="btn btn-primary add text-white mt-2"
                            (click)="openPriceModal(editPricePopup, row, selectedCurrency,true);">{{(row.inrOewPrice !=
                            null
                            &&
                            row.inrOewPrice.price > 0 || row.usdOewPrice != null && row.usdOewPrice.price > 0 ||
                            row.jpyOewPrice != null
                            && row.jpyOewPrice.price > 0 || row.sgdOewPrice != null
                            && row.sgdOewPrice.price > 0 || row.eurOewPrice != null
                            && row.eurOewPrice.price > 0) ? 'Edit Price' : 'Add Price'}} (OEW)</a>
                    </ng-template>
                </ngx-datatable-column>
                <!-- <ngx-datatable-column name="Tax" prop="" [width]="120">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    {{value.tax ? value.tax+'%' : '–'}}
                </ng-template>
            </ngx-datatable-column> -->
                <ngx-datatable-column *ngFor="let col of columns"
                    [name]="col.name"
                    [prop]="col.prop"
                    [width]="125">
                    <ng-template let-value="value"
                        let-row="row"
                        ngx-datatable-cell-template>
                        <div class="column-item"
                            (click)="onCellClicked(row)">
                            <span class="{{ col.prop }}"
                                [ngSwitch]="col.type">
                                <span *ngSwitchCase="'date'">
                                    {{ value | date: 'medium' }}
                                </span>
                                <span *ngSwitchDefault>
                                    <span *ngIf="value=='Inactive'; else active"
                                        class="red">{{value}}</span>
                                    <ng-template #active>
                                        <span>{{value}}</span>
                                    </ng-template>
                                </span>
                            </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template
                        let-rowCount="rowCount"
                        let-pageSize="pageSize"
                        let-selectedCount="selectedCount"
                        let-curPage="curPage"
                        let-offset="offset"
                        let-isVisible="isVisible">
                        <app-paging class="paging tmargin10"
                            [curPage]="curPage"
                            [pageSize]="pageSize"
                            [rowCount]="rowCount"
                            [offset]="offset"
                            [table]="table"
                            (page)="onPageChange($event,1)"
                            [filter]="filterForm.value"></app-paging>
                        <app-paging class="paging place-bottom"
                            [curPage]="curPage"
                            [pageSize]="pageSize"
                            [rowCount]="rowCount"
                            [offset]="offset"
                            [table]="table"
                            (page)="onPageChange($event,1)"
                            [filter]="filterForm.value"></app-paging>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </div>
    <ng-template #ImportProduct>
        <form [formGroup]="uploadForm"
            (ngSubmit)="uploadProduct()">
            <div class="modal-body padding40">
                <h4 class="sure-font text-center">Import Product</h4>
                <div class="tmargin30">
                    <!-- <label for="">Category</label> -->
                    <ng-select [items]="categories"
                        bindLabel="name"
                        bindValue="id"
                        [multiple]="false"
                        formControlName="category_id"
                        placeholder="Select Category"
                        class="form-control"
                        clearAllText="Clear"
                        [clearable]="false">
                    </ng-select>
                </div>
                <!--        <div class="tmargin30 bmargin30 pull-left">-->
                <!--          <label for="is_new">-->
                <!--          <input type="checkbox"-->
                <!--          id="is_new"-->
                <!--          formControlName="is_new"> Create New Product If Shipskart Code Exist</label>-->
                <!--        </div>-->
                <div class="select-file drop-file tmargin30 bmargin20 text-left">
                    <span class="btn-span">+</span>
                    <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                        class="form-control"
                        (change)="selectedfileName($event.target.files)"
                        formControlName="file_name" /> {{fileName ||
                    'Select File'}}
                </div>
                <div class="text-right">

                    <button class="btn btn-outline-primary"
                        (click)="modalRef.hide()"
                        type="button">
                        Cancel
                    </button>
                    <button type="submit"
                        [disabled]="isLoading"
                        class="btn btn-primary text-white">Upload
                        <ng-container *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                        </ng-container>
                    </button>
                </div>
            </div>
            <div class="modal-footer">
                <a href="javascript:void(0)"
                    class="float-left"
                    target="__blank"
                    href="https://shipskart-combined.s3.ap-south-1.amazonaws.com/static/file/product_upload_format.xlsx">
                    New Product Excel
                </a>
            </div>
        </form>
    </ng-template>
    <ng-template #expiryDate>
        <div class="card p-2">
            <h3>Update Expiry Date</h3>
            <div class="card-body">
                <p-calendar appCalendarUiCorrection
                    #calendar
                    placeholder="Select Expiry Date"
                    dateFormat="yy-mm-dd"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="2000:2099"
                    [minDate]="todayDate"
                    (onSelect)="printData(calendar.value)">
                </p-calendar>
            </div>
            <div class="text-right">
                <button class="btn btn-outline-primary"
                    (click)="productExpiry.hide();resetValues()"
                    type="button">Cancel</button>
                <button type="button"
                    class="btn btn-primary"
                    [disabled]="!selectedDate"
                    (click)="openModal(confirmExpiryDate);submitExpiryDate()">Submit</button>
            </div>
        </div>
    </ng-template>
    <ng-template #confirmExpiryDate>
        <div class="modal-body cancelmodal-spc text-center">
            <h4 class="sure-font">Are you sure? </h4>
            <div class="actionbuttons tmargin30">
                <button class="btn btn-outline-danger"
                    (click)="modalRef.hide()"
                    type="button">No</button>
                <button class="btn btn-danger"
                    type="button"
                    (click)="updateExpiryDate();modalRef.hide();productExpiry.hide()">Yes
                    <!-- <ng-container *ngIf="isLoading">
                    <span class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                </ng-container> -->
                </button>
            </div>
        </div>
    </ng-template>
    <ng-template #exportPopup>
        <div class="modal-body padding40 text-center">
            <h4 class="sure-font">Export Products</h4>
            <div class="bmargin20 tmargin20 text-left">
                <label>Select Category<span class="mendatory">*</span></label>
                <div [ngClass]="{error : (submitted && categoryIds && categoryIds.length==0)}">
                    <ng-select class="hide-cross-btn form-control"
                        [items]="categories"
                        #ngCategorySelect
                        bindLabel="name"
                        bindValue="id"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        placeholder="Select Category"
                        [clearOnBackspace]="true"
                        [clearSearchOnAdd]="true"
                        (change)="categorySelected($event)">
                    </ng-select>
                    <div class="errorbox"
                        *ngIf="(submitted && categoryIds && categoryIds.length==0)">Select Category</div>
                </div>
            </div>
            <div class="bmargin20 tmargin20 text-left">
                <label>Select Subcategory</label>
                <ng-select [items]="subCategoryList"
                    [disabled]="categoryIds && categoryIds.length == 0"
                    #ngSubCategorySelect
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    placeholder="Select Subcategory"
                    class="hide-cross-btn rfq-subcatlist export form-control"
                    [(ngModel)]="subCategoryId"
                    [clearOnBackspace]="true"
                    [clearSearchOnAdd]="true"
                    (change)="selectSubcategory($event);">
                </ng-select>
            </div>
            <div class="text-right tmargin10">
                <button class="btn btn-outline-primary"
                    (click)="hideModal();submitted=false"
                    type="button">Cancel</button>
                <button type="submit"
                    (click)="exportProduct()"
                    [disabled]="isLoading"
                    class="btn btn-primary text-white">Submit
                    <ng-container *ngIf="isLoading">
                        <span class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                    </ng-container>
                </button>
            </div>
        </div>
    </ng-template>
    <ng-template #editPricePopup>
        <form [formGroup]="editPriceForm"
            (ngSubmit)="editMode ? editPrice() : addPrice()">
            <div class="modal-body padding30 text-center">
                <h4 class="sure-font">
                    <span *ngIf="isOew;else classic">
                        {{(selectedRow.inrOewPrice != null && selectedRow.inrOewPrice.price > 0 ||
                        selectedRow.usdOewPrice != null && selectedRow.usdOewPrice.price > 0 || selectedRow.jpyOewPrice
                        !=
                        null &&
                        selectedRow.jpyOewPrice.price > 0) ? 'Edit Oew Product Price' : 'Add Oew Product Price'}}
                    </span>
                    <ng-template #classic>
                        {{(selectedRow.inrPrice != null && selectedRow.inrPrice.price > 0 ||
                        selectedRow.usdPrice != null && selectedRow.usdPrice.price > 0 || selectedRow.jpyPrice != null
                        &&
                        selectedRow.jpyPrice.price > 0) ? 'Edit Product Price' : 'Add Product Price'}}
                    </ng-template>
                </h4>
                <div class="row">
                    <div class="bmargin10 text-left col-md-6"
                        *ngIf="role == userRoles.ROLE_ADMIN || role == userRoles.ROLE_SUBADMIN">
                        <label>Vendor Selected<span class="mendatory">*</span></label>
                        <div class="normal-field">
                            <input type="text"
                                class="form-control"
                                [value]="vendorName"
                                readonly />
                        </div>
                    </div>
                    <div class="bmargin10 text-left col-md-6">
                        <label>Port Selected<span class="mendatory">*</span></label>
                        <div class="normal-field">
                            <input type="text"
                                class="form-control"
                                [value]="portName?portName:'N/A'"
                                readonly />
                        </div>
                    </div>
                    <div class="bmargin10 text-left col-md-6">
                        <label>Default Currency<span class="mendatory">*</span></label>
                        <div class="normal-field">
                            <input type="text"
                                class="form-control"
                                [value]="currencyCode"
                                readonly />
                        </div>
                    </div>
                    <div class="bmargin10 text-left col-md-6">
                        <label>Product Price<span class="mendatory">*</span></label>
                        <div class="add-price normal-field"
                            [ngClass]="{error : submitted && form.price.errors}">
                            <span class="currency-code">{{vendor&&
                                vendor.currency_code?vendor.currency_code:vendorData.currency_code}}</span>
                            <input type="text"
                                class="form-control"
                                pKeyFilter="pnum"
                                formControlName="price"
                                maxlength="15"
                                (keyup)="changePrice($event)" />
                            <small class="note-color"
                                *ngIf="(!isOew) &&(form.currency.value === 'INR' && selectedRow.inrPrice != null && selectedRow.inrPrice.price > 0 || form.currency.value === 'USD' && selectedRow.usdPrice != null && selectedRow.usdPrice.price > 0 || form.currency.value === 'JPY' && selectedRow.jpyPrice != null && selectedRow.jpyPrice.price > 0)">
                                Price (Tax Inclusive): {{(form.currency.value === 'INR' && selectedRow.inrPrice != null
                                &&
                                selectedRow.inrPrice.price >= 0) ? selectedRow.inrPrice.price.toFixed(2) :
                                (form.currency.value === 'USD' && selectedRow.usdPrice != null &&
                                selectedRow.usdPrice.price
                                >= 0) ? selectedRow.usdPrice.price.toFixed(2) : (form.currency.value === 'JPY' &&
                                selectedRow.jpyPrice != null && selectedRow.jpyPrice.price >= 0) ?
                                selectedRow.jpyPrice.price.toFixed(2) : ''}} <br>
                            </small>
                            <small class="note-color"
                                *ngIf="(isOew) && (form.currency.value === 'INR' && selectedRow.inrOewPrice != null && selectedRow.inrOewPrice.price > 0 || form.currency.value === 'USD' && selectedRow.usdOewPrice != null && selectedRow.usdOewPrice.price > 0 || form.currency.value === 'JPY' && selectedRow.jpyOewPrice != null && selectedRow.jpyOewPrice.price > 0)">
                                Price (Tax Inclusive): {{(form.currency.value === 'INR' && selectedRow.inrOewPrice !=
                                null
                                &&
                                selectedRow.inrOewPrice.price >= 0) ? selectedRow.inrOewPrice.price.toFixed(2) :
                                (form.currency.value === 'USD' && selectedRow.usdOewPrice != null &&
                                selectedRow.usdOewPrice.price
                                >= 0) ? selectedRow.usdOewPrice.price.toFixed(2) : (form.currency.value === 'JPY' &&
                                selectedRow.jpyOewPrice != null && selectedRow.jpyOewPrice.price >= 0) ?
                                selectedRow.jpyOewPrice.price.toFixed(2) : ''}} <br>
                            </small>
                            <input-validation [control]="form.price"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="bmargin10 text-left col-md-6">
                        <label>Tax<span class="mendatory">*</span></label>
                        <div class="add-price normal-field weight"
                            [ngClass]="{error : submitted && form.tax.errors}">
                            <span class="currency-code">%</span>
                            <input type="text"
                                class="form-control"
                                formControlName="tax"
                                (keyup)="validate($event)"
                                pKeyFilter="pnum" />
                            <small class="error"
                                title="Enter Tax (0 - 99.9)"
                                *ngIf="showError">Invalid Number</small>
                            <input-validation [control]="form.tax"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="bmargin10 text-left col-md-6"
                        *ngIf="isOew">
                        <label>Margin<span class="mendatory">*</span></label>
                        <div class="add-price normal-field weight"
                            [ngClass]="{error : submitted && form.tax.errors}">
                            <span class="currency-code">%</span>
                            <input type="text"
                                class="form-control"
                                formControlName="margin"
                                (keyup)="validate($event)"
                                pKeyFilter="pnum" />
                            <small class="error"
                                title="Enter Tax (0 - 99.9)"
                                *ngIf="showError">Invalid Number</small>
                            <input-validation [control]="form.margin"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="bmargin10 text-left col-md-6">
                        <label>Price Validity (In Days)</label>
                        <input type="text"
                            formControlName="expiryDate"
                            #validityDays
                            pKeyFilter="pnum"
                            class="form-control remark-field"
                            placeholder="Price Validity ( In Days)"
                            (change)="changeDate($event, validityDays.value)"
                            maxlength="3">
                        <span>Expiry Date : {{validityExpiryDate |date:'dd/MM/YYYY'}}</span>
                    </div>
                    <div class="bmargin10 text-left col-md-6">
                        <label>Lead Time (In Days)</label>
                        <input type="text"
                            class="form-control"
                            placeholder="Lead Time (In Days)"
                            pKeyFilter="pnum"
                            formControlName="deliverable_in"
                            maxlength="3">
                    </div>
                    <div class="text-left col-md-6">
                        <label>Brand</label>
                        <ng-select [items]="brands"
                            bindLabel="name"
                            bindValue="name"
                            [multiple]="false"
                            formControlName="brand_name"
                            [closeOnSelect]="true"
                            placeholder="Select Brand"
                            class="hide-cross-btn form-control"
                            [clearOnBackspace]="true"
                            [clearSearchOnAdd]="true"
                            addTagText="Create New"
                            [addTag]="true"></ng-select>
                    </div>
                    <div class="text-left col-md-6">
                        <label>Pack Size</label>
                        <input type="text"
                            class="form-control"
                            placeholder="Enter Pack Size"
                            formControlName="pack_type">
                    </div>
                    <div class="text-left col-md-6">
                        <label>Remark(s)</label>
                        <textarea type="text"
                            class="form-control"
                            placeholder="Enter Remark(s)"
                            formControlName="remark"
                            maxlength="255"></textarea>
                    </div>
                    <div class="text-left col-md-6">
                        <label for="">
                            Attachments
                        </label>
                        <input type="file"
                            class="form-control"
                            (change)="selectedfileNames($event.target.files)"
                            multiple
                            max="5" />
                        <p><strong>Note:</strong><em> Max File size: 5MB</em></p>

                        <div *ngIf="attachedDocuments.length>0">
                            <ng-container *ngFor="let attached of attachedDocuments;let i=index">
                                <span class="">{{i+1}}. {{attached}} </span><br>
                            </ng-container>
                        </div>
                        <div class="errorbox"
                            *ngIf="inValidFileSize">
                            File is larger than <strong>5mb !!</strong>
                        </div>
                    </div>
                    <div class="text-right col-md-12 tmargin20">
                        <button type="button"
                            *ngIf="editMode"
                            (click)="modalRef.hide();openDelete(deletePrice);"
                            [disabled]="isLoading"
                            class="btn btn-danger text-white float-left">Delete Price</button>
                        <button class="btn btn-outline-primary"
                            (click)="modalRef.hide();editPriceForm.reset({currency: 'USD'});submitted=false"
                            type="button">Cancel</button>
                        <button type="submit"
                            [disabled]="isLoading"
                            class="btn btn-primary text-white">Submit
                            <ng-container *ngIf="isLoading">
                                <span class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template #deletePrice>
        <div class="modal-body cancelmodal-spc text-center">
            <h4 class="sure-font">Are you sure? </h4>
            <div class="actionbuttons tmargin30">
                <button class="btn btn-outline-danger"
                    (click)="modalRef.hide()"
                    type="button">Cancel</button>
                <button class="btn btn-danger"
                    type="button"
                    [disabled]="isLoading"
                    (click)="deleteRequest(selectedId);">Delete
                    <ng-container *ngIf="isLoading">
                        <span class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                        <span class="sr-only">Loading...</span>
                    </ng-container>
                </button>
            </div>
        </div>
    </ng-template>

    <ng-template #importProductPrice>
        <form [formGroup]="uploadProductPrices"
            (ngSubmit)="uploadProductPrice()">
            <div class="modal-body padding40 text-center">
                <h4 class="sure-font">Import Product</h4>
                <div class="text-left form-group bmargin20 tmargin20"
                    *ngIf="(((role == userRoles.ROLE_ADMIN &&(vendorId==210)) || (role == userRoles.ROLE_SUBADMIN && vendorId==210)) && productPrices) || (role == userRoles.ROLE_COMPANY_ADMIN && company.type === 2 && (vendorId==210)) || (role == userRoles.ROLE_SUBADMIN && productPrices)">
                    <label for="">Shipping Company</label>
                    <ng-select [items]="shippingCompanyList"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="company_id"
                        [multiple]="false"
                        [placeholder]="shippingCompanyList?'Select Company':'Loading..'"
                        title="Select Vendor"
                        class="form-control"
                        [clearable]="false">
                    </ng-select>
                </div>
                <div class="text-left form-group bmargin20 tmargin20"
                    *ngIf="role">
                    <label for="">Select Type<span class="mendatory">*</span></label>
                    <ng-select class="form-control"
                        [items]="types"
                        placeholder="Select Type"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="type"
                        [multiple]="false"
                        [clearable]="false">
                    </ng-select>
                </div>
                <div class="text-left form-group bmargin20 tmargin20"
                    [ngClass]="{error : submitted && !selectFile}">
                    <label>Select File<span class="mendatory">*</span></label>
                    <div class="select-file drop-file">
                        <span class="btn-span">+</span>
                        <input type="hidden"
                            class="form-control"
                            formControlName="port_id" />
                        <input type="hidden"
                            class="form-control"
                            formControlName="vendor_id" />
                        <input
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            type="file"
                            #inputFile
                            class="form-control"
                            (change)="selectedfileName($event.target.files);"
                            formControlName="file_name" /> {{fileName || 'File Name'}}
                    </div>
                    <div class="errorbox"
                        *ngIf="submitted && !selectFile">
                        <span>Select a file</span>
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-outline-primary"
                        (click)="modelRef.hide();resetProductForm()"
                        type="button">
                        Cancel
                    </button>
                    <button type="submit"
                        [disabled]="!uploadProductPrices.valid  || isLoading || portName===undefined"
                        class="btn btn-primary text-white">Upload
                        <ng-container *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                        </ng-container>
                    </button>
                </div>
            </div>
        </form>
    </ng-template>