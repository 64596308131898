<ng-template #productPopupModel>
    <div class="modal-body cancelmodal-spc">
        <h4 class="sure-font bmargin20 ">{{product.name | titlecase}}</h4>
        <button type="button" class="close close1" aria-label="Close" (click)="modalRef.hide();zoomIn=true;selectedIndex=0;">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="actionbuttons tmargin20">
            <div class="row">
                <div class="col-md-12" *ngIf="!zoomIn">
                    <p class="bmargin30 relative text-center">
                        <span class="zoom-icon fa fa-search-minus" (click)="zoomIn = !zoomIn"></span>
                        <!-- <a *ngIf="!imageLink" href="{{product.images.length != 0 && product.images[0].path ? product.images[0].path  :'/assets/images/logo.svg'}}" target="_blank"> -->
                       <!--  <img class="product-img" [src]="product.images.length != 0 && product.images[0].path ? product.images[0].icon_path :'/assets/images/logo.svg'" (error)="onImgError($event)" />
                        --> <!-- </a> -->
                    </p>
                </div>
                <div class="col-md-12 bmargin20" *ngIf="zoomIn">
                    <ng-container *ngIf="loading">
                        <h4 class="text-center tmargin10 loading">Loading...</h4>
                    </ng-container>
                    <ng-container *ngIf="product.images && product.images.length > 0">
                        <div class="carousel-container bmargin30 image-container" [class.remove-height]="loading">
                            <img *ngFor="let img of product.images; let i=index"
                                [src]="img.path"
                                alt=""
                                [draggable]="false"
                                [ngClass]="{'image-active': selectedIndex===i}"
                                class="fade user-select protected-image" (error)="onImgError($event)" [hidden]="loading" (load)="onLoad()">
                                <div class="overlay"></div>
                        </div>
                        <div *ngIf="indicators && product.images.length > 1"
                            class="carousel-dot-container">
                            <span *ngFor="let img of product.images; let i=index"
                                class="dot" [ngClass]="{'active': selectedIndex===i}"
                                (click)="selectImage(i)">
                            </span>
                        </div>
                        <div *ngIf="selectedIndex > 0"
                            class="btn-carousel btn-prev">
                            <i class="fa fa-chevron-circle-left icon-carousel icon-prev"
                                aria-hidden="true"
                                (click)="onPrevClick()"></i>
                        </div>
                        <div *ngIf="selectedIndex < product.images.length - 1"
                            class="btn-carousel btn-next">
                            <i class="fa fa-chevron-circle-right icon-carousel icon-next"
                                aria-hidden="true"
                                (click)="onNextClick()"></i>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!product.images || (product.images && product.images.length == 0)">
                        <p class="bmargin0 relative res-img">
                            <img class="product-img"
                                src="{{'assets/images/placeholder_image.png'}}" [hidden]="loading" (load)="onLoad()" alt="image_placeholder"
                                >
                        </p>
                    </ng-container>
                </div>
                <div class="{{zoomIn ? 'center-align col-md-8' : 'center-align col-md-12'}}">
                    <p class="bmargin0 text-left"><strong>IMPA Code:</strong> {{product && product.impa_code ? product.impa_code :'N/A'}}</p>
                    <p class="bmargin0 text-left"><strong>Product Code:</strong> {{product && product?.product_code?product?.product_code :'N/A' }}</p>
                    <p class="bmargin0 text-left"><strong>Unit:</strong> {{product && (product?.unit?.name || product?.unit_name) ?(product?.unit?.name || product?.unit_name):'N/A'}}</p>
                    <p class="bmargin0 text-left "><strong>Pack Type:</strong> {{product && product.pack_type ?product.pack_type:'N/A'}}</p>
                    <p class="bmargin20 text-left "><strong>Brand:</strong> {{product && product.brand && product.brand.name?  product?.brand?.name:'N/A'}}</p>
                    <!-- <p class="bmargin20">{{product.description}}</p> -->
                </div>
                <div class="col-md-12">
                    <p class="bmargin10 user-select" [innerHtml]="description" [ngClass]="{'limitTextHeight': isReadMore}"><strong>Description:</strong></p>
                    <a href="javascript:void(0)" (click)="showText()" *ngIf="product && product?.description?.length>200">
                        {{ isReadMore ? 'Read More': 'Read Less' }}
                    </a>
                    <a *ngIf="product?.attachment?.length>0" (click)="attachments()" href="javascript:void(0)">View Attachment(s)</a>
                </div>
            </div>
            <button class="btn btn-outline-primary float-right" (click)="modalRef.hide();zoomIn = true;selectedIndex=0; isReadMore=true" type="submit">Close</button>
        </div>
    </div>
</ng-template>
<ng-template #attachmentView>
    <div class="fluid-container">
        <div class="table-modal-header">
          <h4>Attachments</h4>
          <button type="button" aria-label="Close" class="close"
          aria-label="Close" (click)="attachmentModal.hide()">
            <span aria-hidden="true">&#215;</span>
          </button>
        </div>
        <div class="table-modal-body">
            <table class="table table-bordered">
                <thead>
                  <tr class="text-center">
                    <th>Name</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of product.attachment">
                    <td>{{item.title}}</td>
                    <td class="text-center"><a href="javascript:void(0)" (click)="attachmentUrl(item.url)"><i class="fa fa-eye"></i></a></td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
</ng-template>
