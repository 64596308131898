import { environment } from '@environment/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShipService {
  companyID = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }

  getAll(page, limit, isDropdownSelection = 1, companyId = null, allShips = null, headers = null) {
    let params: HttpParams;
    if (companyId) {
      if (allShips) {
        params = new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('company_id', companyId)
          .set('all_ships', '1')
          .set('is_dropdown_selection', isDropdownSelection.toString());
      } else {
        params = new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('company_id', companyId)
          .set('is_dropdown_selection', isDropdownSelection.toString());
      }
    } else {
      if (allShips) {
        params = new HttpParams()
          .set('page', page)
          .set('limit', limit)
          .set('all_ships', '1')
          .set('is_dropdown_selection', isDropdownSelection.toString());
      } else {
        params = new HttpParams().set('page', page).set('limit', limit)
          .set('is_dropdown_selection', isDropdownSelection.toString());
      }
    }

    let imo_number = null;
    if (headers && headers.get('x-filter')) {
      let x_filter = JSON.parse(headers.get('x-filter'));
      imo_number = x_filter['imo_number'];
      delete x_filter['imo_number'];
      headers = new HttpHeaders().set('x-filter', JSON.stringify(x_filter));
    }

    if (imo_number)
      params.append('imo_number', imo_number)

    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/ship/` + (imo_number ? '?imo_number=' + imo_number : ''), { params, headers })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  addShip(ship: any) {
    return this.http.post<any>(`${environment.apiUrl}/v1/ship/`, ship);
  }

  editShip(ship: any, id) {
    return this.http.put(`${environment.apiUrl}/v1/ship/${id}`, ship);
  }

  getByid(id: number) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/ship/${id}`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }

  getBudgetByid(id: number) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/budget_management/${id}/`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }

  /**
    * @description Deletes a budget category. 
    * This method deletes a budget category based on the provided budget ID and optionally the next budget ID.
    * If both budget ID and next budget ID are provided, both will be deleted.
    * @param {number} budgetId - The ID of the budget to be deleted.
    * @param {number} [nextBudgetId=null] - The ID of the next budget to be deleted (optional).
    * @returns {Observable<any>} - An observable that emits the response data.
    * */
  deleteCategory(budgetId: number, nextBudgetId: number = null) {
    let budget: string | number = budgetId || nextBudgetId; // If budgetId is null then nextBudgetId will be used
    if (budgetId && nextBudgetId) { // If both budgetId and nextBudgetId are present then both will be used
      budget = `${budgetId},${nextBudgetId}`; // Comma separated values
    }
    return this.http.delete<{ data }>(`${environment.apiUrl}/v1/budget_management/${budget}/`).pipe(
      map(dataVal => dataVal.data)
    );
  }

  getShipReport(report, download = 0, formate = 'json') {
    let params = null;
    if (report.port_id != null) {
      params = new HttpParams()
        .set('start', report.start)
        .set('end', report.end)
        .set('download', download.toString())
        .set('format', formate)
        .set('ship_id', report.ship_id)
        .set('port_id', report.port_id);
    } else {
      params = new HttpParams()
        .set('start', report.start)
        .set('end', report.end)
        .set('download', download.toString())
        .set('format', formate)
        .set('ship_id', report.ship_id);
    }

    return this.http.get(
      `${environment.apiUrl}/v1/report/`, { params }
    );
  }
  getShipReportDownload(report, download = 0, formate = 'json') {
    let params = null;
    if (report.port_id != null) {
      params = new HttpParams()
        .set('start', report.start)
        .set('end', report.end)
        .set('download', download.toString())
        .set('format', formate)
        .set('ship_id', report.ship_id)
        .set('port_id', report.port_id);
    } else {
      params = new HttpParams()
        .set('start', report.start)
        .set('end', report.end)
        .set('download', download.toString())
        .set('format', formate)
        .set('ship_id', report.ship_id);
    }

    return this.http.get(
      `${environment.apiUrl}/v1/report/`, { params, responseType: 'blob' }
    );
  }

  getShipSuggestion(search, activeOnly = false, companyId = null) {
    const url = `${environment.apiUrl}/v1/ship/search/${search}`;
    let params = new HttpParams();
    if (activeOnly) {
      params = params.append('status', '1');
    }
    if (companyId != null) {
      params = params.append('company_id', companyId);
    }

    return this.http.get<{ data }>(url, { params }).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  exportAll(params = null, headers = null) {
    return this.http
      .get(
        `${environment.apiUrl}/v1/ship/export?`,
        { params, headers, responseType: 'blob' }
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getCompanyID(value) {
    this.companyID.next(value);
  }

  changeShipCompany(id: number, data: { new_company: number }) {
    return this.http.put(`${environment.apiUrl}/v1/ship/${id}`, data);
  }
}
