<div class="chat-container top-index">
  <div class="chat-left-box">
    <div class="chat-heading-box">
      <span class="head-span">Chats</span>
      <ul class="heading-ul">
        <li>
          <button (click)="addNewChatModal(addNewChatPopup)" type="button"
                  class="btn btn-outline chat-outline dark lmargin10" title="New Chat"><span
            class="fa fa-plus-circle"></span></button>
        </li>
      </ul>
    </div>
    <div class="search-user">
      <form>
        <input type="text" [(ngModel)]="userenter" class="form-control" placeholder="Search Chat" [ngModelOptions]="{standalone: true}">
      </form>
    </div>
    <div class="chat-list-wrap">
      <ul class="chat-list">
        <li *ngFor="let chat of sortedChats | chatfilter:userenter" [class.open-chat]="selectedChat?.id==chat.id"
            (click)="getChatMessages(chat)">
          <div class="icon-div">
            <span class="fa fa-user-circle-o" style="position: relative">
               <span class="user-badge-container">
                  <div class="user-status-badge online" *ngIf="chat.status === 'Online'"></div>
                  <div class="user-status-badge offline" *ngIf="chat.status === 'Offline'"></div>
                  <div class="user-status-badge last-seen"
                        *ngIf="chat.status !== 'Online' && chat.status !== 'Offline'"></div>
                </span>
            </span>
          </div>
          <div class="userlist-div">
            <div class="username">
              <div class="chat-user">
                <h5 class="new-class">{{ chat.member?.fullname }}</h5>
<!--                <span class="user-badge-container">-->
<!--                  <span class="user-status-badge online" *ngIf="chat.status === 'Online'"></span>-->
<!--                  <span class="user-status-badge offline" *ngIf="chat.status === 'Offline'"></span>-->
<!--                  <span class="user-status-badge last-seen"-->
<!--                       *ngIf="chat.status !== 'Online' && chat.status !== 'Offline'"></span>-->
<!--                </span>-->
              </div>
              <p [class.unread-message]="chat.unread_count> 0" *ngIf="chat.last_message">
                <span>{{ chat.last_message.user.id == user.user_id ? 'You: ' : '' }}</span>{{ chat.last_message.message || chat.last_message.file.name }}
              </p>
            </div>
            <div class="userlist-action">
              <div style="display: flex" class="mr-2">
                <small
                  *ngIf="chat.last_message">{{ chat.last_message.created_at | utctolocal | date:'MMM dd, yyyy' }}</small>
              </div>
              <div class="delete-chat-box">
                <div class="unread-count" *ngIf="chat.unread_count > 0">{{ chat.unread_count }}</div>
                <span class="dropdown dropbottom">
                                <button type="button" class="chat-outline chat-settings" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span
                                  class="fa fa-ellipsis-h"></span></button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button class="dropdown-item">Open Chat</button>
                                    <button (click)="deleteChat(chat.id)"
                                            class="dropdown-item text-danger">Delete Chat</button>
                                </div>
                                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="chat-right-box">
    <div class="chat-active-header" *ngIf="selectedChat != null" style="background: white;">
      <div class="chat-active-user">
        <div class="icon-div">
          <span class="fa fa-user-circle-o"></span>
        </div>
        <div class="username">
          <div class="username-header">
            <h5>{{ selectedChat.member?.fullname }}</h5>
            <div class="user-badge-container">
              <div class="user-status-badge online" *ngIf="selectedChat.status === 'Online'"></div>
              <div class="user-status-badge offline" *ngIf="selectedChat.status === 'Offline'"></div>
              <div class="user-status-badge last-seen"
                   *ngIf="selectedChat.status !== 'Online' && selectedChat.status !== 'Offline'"></div>
            </div>
          </div>
          <small *ngIf="selectedChat.status !== 'Online' && selectedChat.status !== 'Offline'">Last Seen at {{ selectedChat.status | utctolocal | date:'MMM dd, yyyy, HH:mm:ss' }}</small>
          <small *ngIf="selectedChat.status == 'Online' || selectedChat.status == 'Offline'">{{ selectedChat.status }}</small>
        </div>
      </div>
      <div class="chat-options">
        <ul>
          <!-- <li><a href="mailto:{{selectedChat.member?.email}}" [title]="selectedChat.member?.email" class="btn btn-outline chat-outline text-green lmargin10"><span class="fa fa-envelope"></span></a></li> -->
          <li>
            <button type="button" title="Close Chat" class="btn btn-outline chat-outline lmargin10"
                    (click)="selectedChat = null"><span class="fa fa-times-circle"></span></button>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-active-body" style="overflow: scroll;" (scroll)="test($event)" #chatWindow>

      <div class="d-flex justify-content-center" *ngIf="loadingMessages && selectedChat != null">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="no-message-container" *ngIf="selectedChat == null">
        <div class="row mt-5 mb-5">
          <div class="col-md-4 offset-4">
            <img src="assets/images/no-chat-message.svg" class="img-fluid" alt="image">
          </div>
        </div>
        <p class="lead">Select a chat to read messages</p>
      </div>
      <div class="chat-messages" *ngIf="selectedChat != null">
        <div #messageDiv class="message-item" *ngFor="let message of messages; let i=index;"
             [class.outgoing-message]="message.user_id == user.user_id">
          <div class="message-content">
            {{ message.message }}
            <div *ngIf="message.file" class="message-attachment" (click)="downloadFile(message.file)">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;{{ message.file.name }}
            </div>
            <span class="message-timestamp"
                  *ngIf="showDateTime"><small>{{ message.created_at | utctolocal | date:'MMM dd, yyyy, HH:mm:ss' }}</small></span>
          </div>
        </div>
      </div>
    </div>
    <div id="newChatActive" class="chat-active-footer" *ngIf="selectedChat != null">
      <form [formGroup]="chatForm">
        <input type="text" #messageInput class="form-control" maxlength="1024" formControlName="message"
               placeholder="Write a message.." [(ngModel)]="message">
        <div class="form-buttons">
          <div>
            <input type="file" formControlName="chooseFile" class="d-none" #file (change)="onChooseFile($event)"/>
            <button (click)="file.click()" class="btn chat-outline dark lmargin10" title="Choose File" type="button"
                    data-original-title="Add files" [disabled]="attachment != null">
              <img src="/assets/images/file-attachment.svg" alt="file-attachment">
            </button>
          </div>
          <button class="btn btn-primary lmargin10" type="submit" title="Send Message" (click)="sendMessage()"
                  [disabled]="(message.trim().length == '' && attachment == null) || sending">
            <ng-container *ngIf="!sending">
              <img src="/assets/images/send-button.svg" alt="send-message">
            </ng-container>
            <ng-container *ngIf="sending">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
            </ng-container>
          </button>
        </div>
      </form>
      <div *ngIf="attachment">
        <p class="d-inline-flex p-2 file-name">{{ attachment.name }}</p>
        <span><a class="text-danger text-decoration-none" (click)="removeFile()">Remove file</a></span>
      </div>
      <div class="text-danger" *ngIf="inValidFileSize">File is larger than <strong>5mb !!</strong></div>
    </div>
  </div>
</div>

<ng-template #addNewChatPopup>
  <div class="modal-body text-left">
    <h4 class="sure-font bmargin20">Add New Chat</h4>
    <div class="row">
      <div class="col-md-12" *ngIf="role == 1">
        <div class="form-group">
          <label>Company<span class="mendatory">*</span></label>
          <ng-select [items]="companys" bindLabel="name" bindValue="id" [multiple]="false" [loading]="loading"
                     (change)="selectCompany($event)" [placeholder]="loading ? 'Loading... ': 'Select Company'"
                     class="form-control" [clearable]="false">
          </ng-select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Select User<span class="mendatory">*</span></label>
          <div class="users-list">
                        <span [class.user-selected]="selectedUser?.user_id == companyUser.user_id"
                              *ngFor="let companyUser of companyUsers" (click)="selectedUser = companyUser">
                            {{
                            !loadingUsers ?
                              companyUser.user_id === user.user_id ? '' : ( companyUser.user_details.fname+ ' ' +companyUser.user_details.lname || '')
                              : ''
                          }}
                        </span>
            <span [class.user-selected]="selectedUser?.user_id == adminObj.user_id" (click)="selectedUser = adminObj"
                  *ngIf="role != 1 && !loadingUsers">Admin</span>
            <span *ngIf="loadingUsers">Loading...</span>
            <span *ngIf="companyUsers.length == 0 && role == 1 && !loadingUsers">No users</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-outline-primary hoverbutton" (click)="closeChatModal()" type="button">Cancel</button>
        <button class="btn btn-primary hoverbutton" (click)="addNewChat()" type="button" [disabled]="!selectedUser">Add Chat
        </button>
      </div>
    </div>
  </div>
</ng-template>
