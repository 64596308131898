export const environment = {
  production: true,
  apiUrl: 'https://api.shipskart.com',
  socketUrl: 'https://api.shipskart.com',
  attachementUrl: 'https://shipskart-combined.s3.ap-south-1.amazonaws.com/',
  firebase: {
    apiKey: 'AIzaSyBLPBG_A2w-79HJGff1RAZd6XHw5Q0kmzw',
    authDomain: 'shipskart-webv2.firebaseapp.com',
    projectId: 'shipskart-web-v2',
  },
};
export const ETSTEUR = "IOPYYT944223631QAZ0PLM83SYITXRAABBRTX";
export const YETTSS = "11908IOIO25385621QAZ0PLM!729TGIEMW1098KLO";