import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from '@app/shared/service/shared.service';

@Component({
  selector: 'app-general-filter',
  templateUrl: './general-filter.component.html',
  styleUrls: ['./general-filter.component.css']
})
export class GeneralFilterComponent implements OnInit {
  @Output() itemClicked: EventEmitter<{ formdata: any, value: any }> = new EventEmitter();
  openInnerList1: boolean;
  showDate1 = '';
  showDate2 = '';
  todayDate = new Date();
  rangeDates1: Date;
  rangeDates2: Date;
  @Input() formData;
  @Input() filterValue;

  constructor(
    private datePipe: DatePipe,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (this.filterValue) {
      const dateRanges = this.filterValue.split(':');
      this.showDate1 = dateRanges[0];
      this.showDate2 = dateRanges[1];
      if (this.showDate1) {
        this.rangeDates1 = new Date(this.showDate1)
      }
      if (this.showDate2) {
        this.rangeDates2 = new Date(this.showDate2)
      }
    }
  }

  customDay
  // Sets date in the particular format and shows it on UI
  selectedDate(valueCheck, value?) {
    const toDate = this.todayDate.getFullYear() + '-' + (this.todayDate.getMonth() + 2) + '-' + 0;
    let lastDate;
    if (valueCheck === 1) {
      this.customDay = null;
      lastDate = new Date(this.datePipe.transform(toDate, 'yyyy-MM-dd'));
      lastDate.setDate(lastDate.getDate() - 175);
      const fromDate = lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + 1;
      this.datePipe.transform(fromDate, 'yyyy-MM-dd');
      this.showDate1 = this.datePipe.transform(fromDate, 'MMM yyyy')
      this.showDate2 = this.datePipe.transform(toDate, 'MMM yyyy');
      this.sharedService.setSelectedData(this.formData.toString(), this.showDate1 + ' - ' + this.showDate2);
      this.itemClicked.emit({ formdata: this.formData.toString(), value: this.showDate1 + ' - ' + this.showDate2 });
    }
    else if (valueCheck === 2) {
      this.customDay = null;
      this.itemClicked.emit(this.formData);
      lastDate = new Date(this.datePipe.transform(toDate, 'yyyy-MM-dd'));
      lastDate.setDate(lastDate.getDate() - 364);
      const fromDate = lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + 1;
      this.datePipe.transform(fromDate, 'yyyy-MM-dd');
      this.showDate1 = this.datePipe.transform(fromDate, 'MMM yyyy')
      this.showDate2 = this.datePipe.transform(toDate, 'MMM yyyy');
      this.sharedService.setSelectedData(this.formData.toString(), this.showDate1 + ' - ' + this.showDate2);
      this.itemClicked.emit({ formdata: this.formData.toString(), value: this.showDate1 + ' - ' + this.showDate2 });
    } else if (valueCheck == 3) {
      this.itemClicked.emit(this.formData);
      //this.customDay = null;
      let date = new Date();
      let lastDate = new Date();
      lastDate.setDate(date.getDate() - this.customDay);
      let fromDate = this.datePipe.transform(lastDate, 'yyyy-MM-dd');
      this.showDate1 = this.datePipe.transform(fromDate, 'MMM yyyy')
      this.showDate2 = this.datePipe.transform(toDate, 'MMM yyyy');
      this.itemClicked.emit({ formdata: this.formData.toString(), value: this.showDate1 + ' - ' + this.showDate2 });
    }
    //this.closeDialogueBox()
  }

  // Resets selected date on UI
  resetDate() {
    this.showDate1 = ''
    this.showDate2 = ''
    //this.closeDialogueBox();
  }

  // Sets date in range format and shows it on UI
  rangeDateSelected(event, rangeDates, val) {
    if (event && rangeDates) {
      this.customDay = null;
      if (val == 1) {
        this.datePipe.transform(this.rangeDates1, 'yyyy-MM-dd');
        this.showDate1 = this.datePipe.transform(this.rangeDates1, 'yyyy-MM-dd')
        this.sharedService.setSelectedData(this.formData.toString(), this.showDate1);
      }
      else {
        this.datePipe.transform(this.rangeDates2, 'yyyy-MM-dd');
        this.showDate2 = this.datePipe.transform(this.rangeDates2, 'yyyy-MM-dd')
        this.sharedService.setSelectedData(this.formData.toString(), this.showDate2);
      }
    }
  }

  submitDate() {
    if (this.showDate1 && this.showDate2) {
      this.itemClicked.emit({ formdata: this.formData.toString(), value: this.showDate1 + ' - ' + this.showDate2 });
    }
    else if (this.showDate1) {
      let date = new Date();
      this.showDate2 = this.datePipe.transform(date, 'yyyy-MM-dd')
      this.sharedService.setSelectedData(this.formData.toString(), this.showDate2);
      this.itemClicked.emit({ formdata: this.formData.toString(), value: this.showDate1 + ' - ' + this.showDate2 });
    }
    this.closeDialogueBox()
  }

  // Close calender dialogue box on UI
  closeDialogueBox() {
    this.openInnerList1 = false;
  }

}
