<div class="fluid-container border" (window:resize)="onResize($event)">
  <div class="header-container bmargin10">
    <div>
      <h4 class="heading bmargin10">{{ data.report_title | capitalize }}</h4>
      <div class="filter-align">
        <div *ngIf="filterLength">Filters:</div>
        <div *ngFor="let filter of data.filters" class="mb-1"
             [ngStyle]="{'display':filterLength?'inline-block':'none'}">
          <span class="filter-selected ml-1 mb-2" *ngIf="filter.filter_value">
            <span class="cross-icon">
              <a class="cross-button" aria-hidden="true" (click)="removeFilter(filter)">&#215;</a>
            </span>
                    {{ filter.filter.name }} : {{ filter.filter_value_in_str || filter.filter_value }}
          </span>
        </div>
      </div>
    </div>
    <div class="pull-right">
      <span class="spinner-border mt-1 mr-1 spinner-border-sm"
            *ngIf="isDownloading"
            role="status"
            aria-hidden="true"></span>
      <span class="sr-only">Loading...</span>
      <a class="more-btn more-link"
        *ngIf="(data?.filters.length>0 || data?.user_id)"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false" href="javascript:void(0)">
        <span class="material-symbols-outlined">
          menu
        </span>
      </a>
      <div class="dropdown-menu float-right"
           aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item"
           href="javascript:void(0)"
           (click)="openFilterModal()"
           *ngIf="data.filters.length>0"><i class="fa fa-filter"></i> Filter</a>
        <a class="dropdown-item"
           href="javascript:void(0)"
           (click)="openPanelModal(true)" *ngIf="data.user_id"><i class="fa fa-edit"></i> Edit</a>
        <a class="dropdown-item"
           href="javascript:void(0)"
           (click)="downloadReport()"><i class="fa fa-download"></i> Download</a>
        <a class="dropdown-item"
           href="javascript:void(0)" *ngIf="data.user_id" (click)="panelDeleteModal(deletePanelModal)"><i
          class="fa fa-trash"></i> Delete</a>
      </div>
    </div>
  </div>
  <div class="bar-chart-container">
    <canvas id="barChart" class="bar-chart" #barCharts height="80"></canvas>
  </div>
</div>
<app-filter [data]="data.filters" [otherAxis]="otherAxis"
            [sharedData]="sharedData"
            #filterData (filterApplied)="filterAppied($event)"></app-filter>
<app-panel [edit]="editPanel"
           [sharedData]="sharedData"
           #panelData (callParentMethodEvent)="reloadPage($event)"></app-panel>

<ng-template #deletePanelModal>
  <div class="modal-body cancelmodal-spc text-center ">
    <h4 class="sure-font">Are you sure to Delete?</h4>
    <div class="actionbuttons tmargin30">
      <button class="btn btn-outline-default cancel-btn"
              (click)="deleteModal.hide()">No
      </button>
      <button class="btn btn-primary"
              (click)="deletePanel()">
              <span class="spinner-border mt-1 mr-1 spinner-border-sm"
                *ngIf="loadingDeletePanel"
                role="status"
                aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>Yes
      </button>
    </div>
  </div>
</ng-template>
