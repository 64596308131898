<div class="container-fluid product-table tmargin20">
  <div class="d-flex tmargin20 bmargin20 justify-content-between">
    <h5 class="order-data" *ngIf="(role== userRole.ROLE_COMPANY_ADMIN && currentCompanyType==1  )">Previous Purchase</h5>
    <h5 class="order-data" *ngIf="((role== userRole.ROLE_ADMIN) ||(role== userRole.ROLE_SUBADMIN)||(role== userRole.ROLE_COMPANY_ADMIN && currentCompanyType==2))" >Order History</h5>
    <!-- <button class="btn btn-primary"
      (click)="downloadPriceTrend()">Download Price History <i class="fa fa-download"></i> <ng-container *ngIf="exports">
        <span class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"></span>
        <span class="sr-only">Loading...</span>
      </ng-container></button> -->
    <!-- <div class="hamburger"
    (click)="openModal()"> -->
  </div>
  <table class="table table-container" *ngIf="hasOrderData; else noData">
    <thead>
      <tr>
        <th class="text-center" style="width: 68px;">Sr. No.</th>
        <th class="text-center">Transaction Id / Name</th>
        <th class="text-center">Date of Delivery</th>
        <th class="text-center">Category</th>
        <th class="text-center">Ship / Port</th>
        <th class="text-center">Company</th>
        <th class="text-center">Order Amount</th>
        <th class="text-center"
          *ngIf="(role== userRole.ROLE_ADMIN || role== userRole.ROLE_SUBADMIN)">Discount</th>
        <th class="text-center"
          *ngIf="role==userRole.ROLE_ADMIN || role==userRole.ROLE_SUBADMIN">Margin Earned</th>
        <th class="text-center"
          style="border-right: none; padding-right: 0px;">Order Rating</th>
        <th class="text-center"
          style="border-left: none;"></th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr *ngFor="let item of priceTrend;let i =index"> -->
      <tr *ngFor="let item of orderData; let i = index">
        <td class="product text-center">
          <h5> {{i+1}}</h5>
        </td>
        <td class="product">
          <h5 class="item-color cursor-pointer" (click)="onSelect(item)">{{item.order_number}}<br>
            <span [style.backgroundColor]="'0074f2'" class="date-order cursor-pointer" (click)="onSelect(item)">{{item.order_name}}</span></h5>
        </td>
        <td class="text-center">
          {{item.delivery_date|date}}
        </td>
        <td class="text-center">
          {{item.categories[0]}}
        </td>
        <td class="product">
          {{item.ship_detail}} /<br> <span class="item-color">{{item.port_name}}</span>
        </td>
        <td class="text-center">
          {{item.company_name}}
        </td>
        <td class="text-center">
          <b> {{item.currency_code}} {{ item.total_amount | twoDigitNumber}}</b>
        </td>
        <td class="text-center"
          *ngIf="(role == userRole.ROLE_ADMIN || role == userRole.ROLE_SUBADMIN )">
          <b> {{item.currency_code}} {{item.discount_amount}}</b>
        </td>
        <td class="text-center"
          *ngIf="(role == userRole.ROLE_ADMIN || role == userRole.ROLE_SUBADMIN )">
<!--          <b> {{item.currency_code}} {{calculateMargin(item.order_detail)}}</b>-->
          <b> {{item.currency_code}} {{item.total_margin || 0 | twoDigitNumber}}</b>
        </td>
        <td class="text-center">
          <button class="rating-data"
            *ngIf="item.rating; else fallback">
            {{item.rating}} <i class="fa fa-star"
              style="color: #FFB624;"> </i>
          </button>
          <ng-template #fallback>
            N/A
          </ng-template>
        </td>
        <td class="text-center">
          <div class="dropdown">

              <button class="button-more" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" (click)="onDeliveryNoteClick($event, item)">Delivery Note</a>
                <a class="dropdown-item" href="#" (click)="onFeedbackBackClick($event, item)">Feedback Form</a>
              </div>

          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <tfoot class="fotbar">
    <tr class="tfoot">
      <nav aria-label="Page navigation example" class="tmargin10">
        <ul class="pagination" style="margin-bottom: 10px;">
          <li class="page-item">
            <button class="btn " href="javascript.void(0)">
              {{ page.offset }} - {{ page.count }} of {{ totalItems }}
            </button>
          </li>
          &nbsp;
          <li class="page-item">
            <button class="btn btn-outline-dark cust-btn page-btn" aria-label="Previous" [disabled]="!hasPrev" (click)="setTablePage('prev')">
              <span aria-hidden="true"><</span>
              <span class="sr-only">Previous</span>
            </button>
          </li>


          &nbsp;
          <li class="page-item">
            <button class="btn btn-outline-dark cust-btn page-btn" aria-label="Next" [disabled]="!(page.offset<page.count && hasNext)" (click)="setTablePage('next')">
              <span aria-hidden="true" >></span>
              <span class="sr-only">Next</span>
            </button>
          </li>
        </ul>
      </nav>
    </tr>
  </tfoot> -->
</div>
<ng-template #noData>
  <div class="no-data-container">
    <p class="no-data-text">No Data Available</p>
  </div>
</ng-template>

<!-- <ng-template #viewHistory>
  <div class="modal-body padding30 view-history">
    <h4 class="sure-font bmargin20 text-left price-history-head">Product Price History</h4>
    <button type="button"
      class="close pull-right"
      (click)="modalRef.hide();">
      <span>&times;</span></button>
    <div class="vendor-wrapper">
      <div class="price product-details">
        <h4 class="head">Product Details</h4>
        <div class="detail-content">
          <div class="img-box">
            <img class="product-img"
              [src]="(productInfo && productInfo.images && productInfo.images.length != 0 && productInfo.images[0].icon_path) ? productInfo.images[0].icon_path : '/assets/images/logo.svg'"
              (error)="onImgError($event)" />
          </div>
          <div class="content-box">
            <p>Product Name<span class="p-name user-select">{{selectedProduct && selectedProduct[0].name ?
                selectedProduct[0].name :
                'N/A'}}</span></p>
            <p>IMPA Code:<span>{{selectedProduct && selectedProduct[0].impa_code? selectedProduct[0].impa_code :
                'N/A'}}</span></p>
            <p class="remove-margin"> Shipskart Code:<span>{{selectedProduct && selectedProduct[0].product_code ?
                selectedProduct[0].product_code: 'N/A'}}</span></p>
          </div>
        </div>
      </div>
      <div class="price product-details vendor-details">
        <h4 class="head">Vendor Details</h4>
        <div class="detail-content">
          <div class="img-box">
            <img src="/assets/images/vendor-picture.svg"
              alt="">
          </div>
          <div class="content-box">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <p>Vendor Name<span>{{selectedProduct[0]?.vendors[0]?.vendor_name ?
                    (selectedProduct[0]?.vendors[0]?.vendor_name |titlecase):'N/A'}}</span>
                </p>
                <p class="remove-margin">Last Delivered
                  Order<span>{{selectedProduct[0]?.vendors[0]?.vendor_delivery_strength ?
                    selectedProduct[0]?.vendors[0]?.vendor_delivery_strength : 'N/A'}}</span></p>
              </div>
              <div class="col-md-12">
                <p>Vendor Rating<br><span
                    class="rating-box vendor-select d-flex align-content-center" style="color: #fff;">{{selectedProduct[0]?.vendors[0]?.vendor_rating ?
                    selectedProduct[0]?.vendors[0]?.vendor_rating :'N/A'}} <i class="fa fa-star" style="color: gold;margin-top:3px !important"></i></span></p>
                <p>Port <span *ngFor="let port of selectedProduct[0]?.vendors[0]?.ports">{{port.name}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="graph-wrapper">
      <div class="data-table products scrollbar">
        <h4 class="sure-font bmargin10 text-left price-history-subhead">Price History</h4>
        <div class="scroll-table">
          <table class="table full-table req-table price update history ">
            <tbody>
              <app-analytics-linechart [chartData]="selectedProduct"></app-analytics-linechart>
              <tr class="no-data"
                *ngIf="selectedProduct[0]?.vendors && selectedProduct[0]?.vendors.length === 0">
                <td colspan="6">No Orders Available!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="data-table products scrollbar"
        style="margin-top: 70px;">
        <h4 class="sure-font tmargin20 text-left price-history-subhead"></h4>
        <table class="table-container">
          <thead>
            <tr>
              <th width="120">Sr No.</th>
              <th>Date Updated</th>
              <th>Product Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of selectedProduct[0]?.vendors;let i =index">
              <td>{{i+1.}}</td>
              <td>{{row?.updated_date | date:'dd MMM yyyy'}}</td>
              <td>{{row?.vendor_price? row?.vendor_currency + ' '+row?.vendor_price:'N/A'}}</td>
            </tr>
            <tr class="no-data"
              *ngIf="selectedProduct[0]?.vendors && selectedProduct[0]?.vendors.length === 0">
              <td colspan="5">No Prices Available!</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template> -->
