import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ValidateService {

  //Validate time user can't select future time
  validateTimeInput(
    date: string | Date,  // Allow both string and Date as input
    value: string,
    type: 'hour' | 'minute' | 'second',
    selectedHour?: number,
    selectedMinute?: number
  ): number {
    const numValue = parseInt(value.trim(), 10);
    if (isNaN(numValue)) {
      return -1; // Invalid input
    }
    // Convert string to Date if needed

    if (typeof date === "string") {
      let [day, month, year, time] = date.split(/[- ]/);
      let [hours, minutes] = time.split(":");
      new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes)))
    }
    const inputDate = typeof date === 'string' ? new Date(date) : date;
    const now = new Date();
    // Check if the given date is today
    const isToday =
      inputDate.getFullYear() === now.getFullYear() &&
      inputDate.getMonth() === now.getMonth() &&
      inputDate.getDate() === now.getDate();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    if (type === 'hour') {
      if (numValue >= 0 && numValue <= 23) {
        return isToday && numValue > currentHour ? currentHour : numValue; // Restrict future hours if today
      }
      return currentHour;
    }
    if (type === 'minute') {
      if (numValue >= 0 && numValue <= 59) {
        return isToday && selectedHour === currentHour && numValue > currentMinute
          ? currentMinute
          : numValue;
      }
      return currentMinute;
    }
    return -1; // Invalid case
  }

  //Validate use input 
  numberOnly(event: KeyboardEvent, inputValue: string): boolean {
    const key = event.key;
    // Allow numbers (0-9), backspace, and a single decimal point
    if (!/^\d$/.test(key) && key !== "Backspace" && key !== ".") {
      return false;
    }
    // Prevent multiple decimal points
    if (key === "." && inputValue.includes(".")) {
      return false;
    }
    return true;
  }
}