import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  isDelete = new BehaviorSubject(false);
  currentDelete = this.isDelete.asObservable();
  constructor(
    private http: HttpClient,
    private toaster: ToastrService
  ) { }

  // Returns listing of all the dashboard reports
  getDashboardReports() {
    return this.http.get(`${environment.apiUrl}/v1/dashboard/report_ids`);
  }

  getFiltersOptions(report_id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/dashboard/reports/filters/${report_id}`).pipe(
      map((dataVal) => {
        return dataVal;
      }));
  }

  getVendors() {
    return this.http.get(`${environment.apiUrl}/inventory_api/contacts/?page=1&limit=500&contact_type=vendor`)
  }

  getCategoriesAgainstVendor(vendorId) {
    return this.http.get(`${environment.apiUrl}/v1/company/${vendorId}/category`);
  }

  paginateTable(report_id, page) {
    return this.http.get(`${environment.apiUrl}/v1/dashboard/reports/${report_id}?page=${page}`).pipe(
      map((dataVal) => {
        return dataVal;
      }));
  }

  // Returns fields for data configuration modal
  getPanelData() {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/dashboard/report_options`).pipe(
      map((dataVal) => {
        return dataVal;
      }));
  }

  // Create New Panel
  createPanel(newPaneldata) {
    const headers = { 'content-type': 'application/json' }
    return this.http.post(`${environment.apiUrl}/v1/dashboard/reports`, newPaneldata, { headers: headers })
  }

  // Gets report data via report id
  async getReportData(report_id, headers, exportData) {
    if (exportData) {
      let params = new HttpParams();
      params = params.append('export', '1');
      const blob = await this.http.get(`${environment.apiUrl}/v1/dashboard/reports/${report_id}`, { params, responseType: 'blob' }).toPromise();
      return blob;
    } else {
      const response = await this.http.get<{ data }>(`${environment.apiUrl}/v1/dashboard/reports/${report_id}`, { headers: headers }).toPromise();
      return response.data;
    }
  }

  // Updates report data
  updateReportData(reportData, report_id) {
    return this.http.put(`${environment.apiUrl}/v1/dashboard/reports/${report_id}`, reportData);
  }

  // Submit feedback form
  submitFeedbackForm(formData) {
    return this.http.post(`${environment.apiUrl}/v1/dashboard/feedback_form`, formData);
  }

  // Get list of created feedback forms
  getFeedbackForms(formId?: number, is_self?: number, isGuest = false) {
    if (formId) {
      let params = new HttpParams().set('form_id', formId.toString())
      return this.http.get<{ data }>(`${environment.apiUrl}/v1/dashboard/${isGuest ? 'guest_' : ''}feedback_form`, { params }).pipe(
        map((dataval) => dataval.data));
    } else {
      if (is_self) {
        const params = new HttpParams().set('is_self', is_self.toString());
        return this.http.get(`${environment.apiUrl}/v1/dashboard/feedback_form`, { params });
      }
      else {
        let params = new HttpParams().set('limit', '100')
        return this.http.get(`${environment.apiUrl}/v1/dashboard/feedback_form`, { params });
      }

    }
  }

  //Update Created feedbackform those assigned to subadmin
  updateForm(payload) {
    return this.http.put(`${environment.apiUrl}/v1/dashboard/feedback_form`, payload)
  }

  // Assign Form To The Users
  assignForm(userData, formId) {
    return this.http.post(`${environment.apiUrl}/v1/dashboard/feedback_form_assignment/${formId}`, userData)
  }

  //Assigned Subadmin
  getAssignedSubadminForm(form_id) {
    const params = new HttpParams().append('form_id', form_id)
    return this.http.get(`${environment.apiUrl}/v1/dashboard/feedback_form_assignment`, { params })
  }

  //Get Submission Api Data
  async fetchSubmissionData(formId: number, userId: number, orderId: number, guestId = 0) {
    try {
      const response = await firstValueFrom(this.http.get<{ data }>(`${environment.apiUrl}/v1/dashboard/feedback_form_submission/${formId}/${userId}/${orderId}/${guestId ? guestId : 0}`));

      if (response.data) {
        return { data: response.data, order_detail: response["order_details"] };
      } else {
        return { order_detail: response["order_details"] };
      }
    } catch (error) {
      this.toaster.error(error['error']);
    }
  }

  //Post Submission Api Data
  submissionForm(formId: number, userId: number, orderId: number, formData, isGuest = false) {
    if (isGuest) {
      return this.http.post(`${environment.apiUrl}/v1/dashboard/${isGuest ? 'guest_' : ''}feedback_form_submission/${formId}`, formData)
    }
    return this.http.post(`${environment.apiUrl}/v1/dashboard/${isGuest ? 'guest_' : ''}feedback_form_submission/${formId}/${userId}/${orderId}`, formData)
  }

  //Post Submission Api Data
  getFeedbackFormAssignedUser(formId: number, orderId) {
    let params = new HttpParams().append('form_id', formId.toString())
    if (orderId)
      params = params.append('order_id', orderId)
    return this.http.get(`${environment.apiUrl}/v1/dashboard/feedback_form_assignment`, { params }).pipe(map(data => {
      return data
    }))
  }

  // Get List Of Form Submission
  getListOfForms(form_id, order_id, params) {
    return this.http.get(`${environment.apiUrl}/v1/dashboard/feedback_form_submission_list/${form_id}/${order_id}`, { params }).pipe(map(dataVal => {
      return dataVal['data'];
    })
    );
  }

  //Get Users Assigned and Not Assigned list for Feedback Form
  getUserList(params, headers = null) {
    return this.http.get(`${environment.apiUrl}/v1/user/`, { headers, params }).pipe(map(dataVal => {
      return dataVal['data'];
    })
    )
  }

  /* Remove Report ById */
  deleteReport(newQuote: boolean) {
    this.isDelete.next(newQuote);
  }

  getPriceTrend(params?, vendor?, page?, limit?, type?) {
    if (type != undefined && type != null) {
      return this.http.post(`${environment.apiUrl}/v1/dashboard/vendor/analytics/price_trend`, vendor, { params, responseType: "blob", }).pipe(map(data => data));
    } else {
      return this.http.post(`${environment.apiUrl}/v1/dashboard/vendor/analytics/price_trend`, vendor, { params }).pipe(map(data => data));
    }
  }

  getVendorAnalyticsReports(vendorId) {
    var headers = new HttpHeaders().set(
      'x-filter',
      JSON.stringify({ vendor_id: vendorId }))
    return this.http.get(`${environment.apiUrl}/v1/dashboard/vendor/analytics/data_card`, { headers }).pipe(map(data => data));
  }

  getVendorAnalytics(headers, id?) {
    if (id) {
      const params = { report_type: id };
      return this.http.get(`${environment.apiUrl}/v1/dashboard/vendor/analytics/graph`, { headers, params }).pipe(map(data => data));
    } else {
      return this.http.get(`${environment.apiUrl}/v1/dashboard/vendor/analytics/graph`, { headers }).pipe(map(data => data));
    }
  }

}
